/* variables */
/* common */
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 3px 0;
  font-family: 'Playfair Display', serif;
  font-size: initial;
  font-weight: normal;
  color: #5a5b5e;
}
p,
ul,
li {
  margin: 0;
  padding: 10px;
}
figure {
  margin: 0;
}
/* mixins */
.text-indent {
  display: inline-block;
  text-indent: 110%;
  white-space: nowrap;
}
.content-padding {
  padding: 0 15px;
}
@media screen and (min-width: 1280px) {
  .content-padding {
    padding: 0 55px;
  }
}
.see-more-btn,
.about .see-more,
.magazines .see-more,
.news .see-more,
.video .see-more,
.magazines-list .thumb .see-more,
.websites-list .back .see-more,
.article-list .list .see-more,
.websites .see-more,
.special-projects .see-more,
.studio-foto .see-more,
.video-widget .see-more {
  display: inline-block;
  margin-top: 1em;
  padding: 7px 40px;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  transition: background-color 0.5s;
}
.see-more-btn:after,
.about .see-more:after,
.magazines .see-more:after,
.news .see-more:after,
.video .see-more:after,
.magazines-list .thumb .see-more:after,
.websites-list .back .see-more:after,
.article-list .list .see-more:after,
.websites .see-more:after,
.special-projects .see-more:after,
.studio-foto .see-more:after,
.video-widget .see-more:after {
  content: ">";
  padding-left: 3px;
}
.see-more-btn:hover,
.about .see-more:hover,
.magazines .see-more:hover,
.magazines a:hover .see-more,
.news .see-more:hover,
.video .see-more:hover,
.magazines-list .thumb .see-more:hover,
.websites-list .back .see-more:hover,
.article-list .list a:hover .see-more,
.article-list .list .see-more:hover,
.websites .see-more:hover,
.websites a:hover .see-more,
.special-projects .see-more:hover,
.special-projects a:hover .see-more,
.studio-foto .see-more:hover,
.studio-foto a:hover .see-more,
.video-widget .see-more:hover {
  border-color: #D3CCD3;
  background-color: rgba(211, 204, 211, 0.35);
  color: #000;
}
@media screen and (min-width: 980px) {
  .see-more-btn,
  .about .see-more,
  .magazines .see-more,
  .news .see-more,
  .video .see-more,
  .magazines-list .thumb .see-more,
  .websites-list .back .see-more,
  .article-list .list .see-more,
  .websites .see-more,
  .special-projects .see-more,
  .studio-foto .see-more,
  .video-widget .see-more {
    padding: 10px 50px;
    font-size: 17px;
  }
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: "";
  clear: both;
  display: block;
  overflow: hidden;
}
.container {
  padding: 0 15px;
}
@media screen and (min-width: 1280px) {
  .container {
    padding: 0 55px;
  }
}
.mx-header-underscore,
.slider .item h3:after,
.magazines-list .thumb h2:after,
.websites-list .front .social:before,
.magazine-single .header:after,
.magazine-single .description h1:after,
.magazine-single .description h2:after,
.magazine-single .description h3:after,
.magazine-single .description h4:after,
.magazine-single .description h5:after,
.subpage h1:after,
.subpage h2:after,
.subpage h3:after,
.subpage h4:after,
.subpage h5:after,
.full.subpage .header .title:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
}
.breadcrubs {
  display: none;
  margin: 0 auto 30px auto;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}
.breadcrubs .item {
  display: inline-block;
}
.breadcrubs .separator {
  padding: 0 3px;
}
.paginator {
  margin-bottom: 20px;
  text-align: center;
}
.paginator .item {
  display: inline-block;
  border: 1px solid #B7B8BA;
  margin: 4px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #B7B8BA;
  font-size: 14px;
  text-align: center;
}
.paginator .selected,
.paginator a.item:hover {
  background-color: #707175;
  color: #fff;
}
.paginator .more {
  border-color: #fff;
}
.cookie-policy-box {
  position: fixed;
  bottom: 10px;
  left: 50%;
  border: 1px solid #333;
  padding: 10px;
  margin-left: -40%;
  width: 80%;
  z-index: 1000;
  font-size: 13px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  line-height: 18px;
  box-sizing: border-box;
}
.cookie-policy-box .link {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.cookie-policy-box .accept {
  margin-left: 10px;
  font-weight: bold;
  color: #91cfd0;
  cursor: pointer;
  float: right;
}
.flash-hide {
  display: none;
}
.flash-error,
.flash-alert,
.flash-notice,
.flash-success,
.flash-info {
  padding: 0.8em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 2px solid #ddd;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.flash-error,
.flash-alert {
  background: #fbe3e4;
  color: #8a1f11;
  border-color: #fbc2c4;
}
.flash-notice {
  background: #fff6bf;
  color: #514721;
  border-color: #ffd324;
}
.flash-success {
  background: #e6efc2;
  color: #264409;
  border-color: #c6d880;
}
.flash-info {
  background: #d5edf8;
  color: #205791;
  border-color: #92cae4;
}
.flash-error a,
.alert a {
  color: #8a1f11;
}
.flash-notice a {
  color: #514721;
}
.flash-success a {
  color: #264409;
}
.flash-info a {
  color: #205791;
}
label.required:after {
  content: "*";
  padding: 2px 0 0 3px;
  color: #D91400;
}
.form-errors {
  color: #f00;
  white-space: nowrap;
}
body {
  padding: 15px 0 0;
  margin: 0;
  background: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #8c8c8c;
}
.common-header {
  position: relative;
  margin-bottom: 5px;
}
.common-header h1 {
  display: inline-block;
  margin: 0;
}
.common-header .logo {
  display: inline-block;
  max-width: 18vw;
  transition: 0.5s;
}
.common-header .menu-btn {
  float: right;
  padding: 0;
  border: 0;
  margin: -5px 0 0;
  color: #B4B5B7;
  font-size: 10vw;
  background: none;
  outline: 0;
}
.common-header .navigation-box {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 75%;
  background: #fff;
  z-index: 100;
}
.common-header .navigation .item {
  position: relative;
  display: block;
  padding: 15px;
  transition: 0.5s;
}
.common-header .navigation .item.selected .label {
  color: #be3541;
}
.common-header .navigation .item .link:hover .label {
  color: #be3541;
}
.common-header .navigation .item .label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
}
.common-header .navigation .submenu {
  display: none;
  position: absolute;
  left: -20px;
  top: 100%;
  padding: 0 30px 20px 30px;
  background: #fff;
  z-index: 200;
  box-shadow: 0 7px 11px rgba(0, 0, 0, 0.2);
}
.common-header .navigation .submenu .submenu-label {
  display: block;
  position: relative;
  padding: 10px 50px 10px 0;
  white-space: nowrap;
  border-bottom: 1px solid #eee;
  text-align: left;
}
.common-header .navigation .submenu .submenu-label:after {
  content: "";
  position: absolute;
  bottom: 0;
  display: block;
  border-bottom: 3px solid transparent;
  width: 100%;
}
.common-header .navigation .submenu .submenu-label:hover {
  color: #be3541;
}
.common-header .navigation .submenu .submenu-label:hover:after {
  border-color: #000;
}
.common-header .search-form {
  display: none;
}
.common-header .search-form .fa-search {
  display: none;
  transition: 0.5s;
}
.js .common-header .search-form .fa-search {
  display: inline-block;
}
.js .common-header .search-form .input-submit {
  display: none;
}
.common-header.expanded .navigation-box {
  display: block;
}
.page-footer {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.page-footer .common-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #000;
}
.page-footer a {
  color: #fff;
}
.page-footer .logo {
  display: inline-block;
  margin: 0 auto;
  max-width: 25vw;
}
.page-footer .navigation-box {
  display: block;
  margin-top: 10px;
}
.page-footer .navigation-box .item {
  display: inline-block;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 13px;
}
.page-footer .navigation-box .item .label {
  white-space: nowrap;
}
.page-footer .navigation-box .item:hover .label {
  color: #be3541;
}
.page-content {
  padding-top: 50px;
  background-image: linear-gradient(to bottom, #e3e3e3 0, rgba(250, 250, 250, 0.5) 10px, rgba(255, 255, 255, 0.1) 15px, transparent 16px);
}
.hp-page .page-content,
.subpage-page .page-content {
  padding-top: 0;
}
.with-bc .page-content {
  padding-top: 35px;
}
.about {
  margin-top: 40px;
  margin-bottom: 50px;
  text-align: center;
}
.about .header,
.news .header,
.magazines-list .header,
.websites-list .header,
.other-magazines .header,
.important-events .header,
.article-list .header,
.video-list .header,
.other-websites .header {
  position: relative;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 3px;
}
.about .header:before,
.news .header:before,
.magazines-list .header:before,
.websites-list .header:before,
.other-magazines .header:before,
.important-events .header:before,
.article-list .header:before,
.video-list .header:before,
.other-websites .header:before {
  position: absolute;
  content: " ";
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #ABACAE;
  z-index: -1;
}
.about .header .title,
.news .header .title,
.magazines-list .header .title,
.websites-list .header .title,
.other-magazines .header .title,
.important-events .header .title,
.article-list .header .title,
.video-list .header .title,
.other-websites .header .title {
  display: inline-block;
  padding: 0 25px;
  margin: 0 auto;
  background: #fff;
  text-transform: uppercase;
  font-size: 25px;
}
.about .content {
  padding: 0 15px;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8em;
  text-align: justify;
}
@media screen and (min-width: 1280px) {
  .about .content {
    padding: 0 55px;
  }
}
.about .contact-info,
.about .photos,
.subpage .contact-info {
  display: none;
}
.magazines,
.websites,
.special-projects,
.studio-foto {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #B30519;
  color: #fff;
  text-align: center;
}
.magazines .container,
.websites .container,
.special-projects .container,
.studio-foto .container {
  position: relative;
}
.magazines .header,
.websites .header,
.special-projects .header,
.studio-foto .header {
  margin: 0;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  font-weight: 300;
  color: #fff;
}
.magazines .content,
.websites .content,
.special-projects .content,
.studio-foto .content {
  display: none;
  max-width: 625px;
  font-weight: 400;
}
.websites {
  background: #66cbcb;
}
.special-projects {
  background: #5a99cd;
}
.studio-foto {
  background: #73566c;
}
.news {
  margin-top: 40px;
  padding-bottom: 30px;
  text-align: center;
}
.news .header {
  line-height: 100%;
}
.news .content {
  padding: 0 15px;
}
.news .content .item {
  border: 1px solid #b4b5b7;
  padding: 15px;
  text-align: left;
}
.news .content .item:hover img {
  opacity: 0.5;
}
.news .content .thumb {
  text-align: center;
}
.news .content .thumb img {
  transition: opacity 0.5s;
}
.news .content h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.1em;
}
.news .content h3:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
  margin-top: 6px;
  width: 25vw;
}
.news .content .description {
  margin-top: 8px;
  font-size: 14px;
}
.video {
  position: relative;
  background: #E9EAEC;
}
.video .header {
  padding: 5vw 25vw;
  margin-bottom: 0;
  background: #B20519;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.video .header .title {
  display: block;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.2em;
}
.video .header .title:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 25vw;
  border-color: #fff;
}
.video .header .see-more,
.video-widget .header .see-more {
  display: none;
  font-family: Lato;
}
.video .header .see-more:after,
.video-widget .header .see-more:after {
  display: none;
}
.video .content {
  padding: 5vw 15px;
  background: #E9EAEC;
  text-align: center;
}
.video .content .item {
  margin: 0 auto 1.5em auto;
  max-width: 500px;
  text-align: left;
}
.video .content .item:hover h3 {
  color: #fff;
  background: #000;
}
.video .content .item:hover h3:before {
  position: absolute;
  left: 0;
  top: -4px;
  content: "";
  display: inline-block;
  width: 100%;
  height: 4px;
  background: #be3541;
}
.video .content .thumb {
  position: relative;
}
.video .content .thumb:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -38px 0 0 -38px;
  width: 76px;
  height: 76px;
  background: url(i/video_ico.png) no-repeat;
}
.video .content h3 {
  position: relative;
  margin: 0;
  padding: 10px 5px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2em;
  transition: background-color 0.5s;
}
.video .see-more,
.video-widget .see-more {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.video .see-more:after,
.video-widget .see-more:after {
  display: none;
}
.video-widget {
  padding: 15px;
  margin-top: 20px;
  background: #E9EAEC;
  text-align: center;
}
.video-widget .header {
  margin-top: 0;
}
.video-widget .header .title {
  text-transform: uppercase;
  font-size: 25px;
}
.video-widget .header .title:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border-color: inherit;
}
.video-widget .list .item {
  margin: 0 auto 1.5em auto;
  max-width: 500px;
  text-align: left;
}
.video-widget .list .item:hover h3 {
  color: #fff;
  background: #000;
}
.video-widget .list .item:hover h3:before {
  position: absolute;
  left: 0;
  top: -4px;
  content: "";
  display: inline-block;
  width: 100%;
  height: 4px;
  background: #be3541;
}
.video-widget .list .thumb {
  position: relative;
}
.video-widget .list .thumb:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -38px 0 0 -38px;
  width: 76px;
  height: 76px;
  background: url(i/video_ico.png) no-repeat;
}
.video-widget .list h3 {
  position: relative;
  margin: 0;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2em;
  transition: background-color 0.5s;
}
.js .gmap {
  height: 320px;
}
.slider .item {
  display: none;
  text-align: center;
}
.slider .item:first-child {
  display: block;
}
.slider .item .thumb {
  display: block;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
}
.slider .item .image {
  display: block;
  width: 100%;
}
.slider .item .content {
  position: relative;
  padding: 15px 15px;
  color: #fff;
  background: #be3541;
}
.slider .item h3 {
  margin: 0 0 20px 0;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 25px;
  color: #fff;
}
.slider .item h3:after {
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  border-color: #fff;
}
.slider .item p {
  padding: 0 30px 10px 30px;
  font-weight: 400;
}
.slider .item .prev,
.slider .item .next {
  display: none;
  position: absolute;
  top: 15px;
  font-size: 40px;
  cursor: pointer;
}
.slider .item .prev {
  left: 15px;
}
.slider .item .next {
  right: 15px;
}
.slider .item:nth-child(2) .content {
  background: rgba(102, 203, 203, 0.85);
}
.js .slider .item {
  display: block;
}
.js .slider .item .prev,
.js .slider .item .next {
  display: inline-block;
}
.magazines-list {
  text-align: center;
}
.magazines-list .header {
  margin-top: 0;
}
.magazines-list .description,
.websites-list .description {
  margin: 0 auto;
  width: 80%;
  font-weight: 400;
  font-size: 19px;
}
.magazines-list .list,
.magazines-list .item {
  list-style: none;
}
.magazines-list .list {
  margin: 30px 0 0;
  padding: 0;
}
.magazines-list .item {
  margin: 0;
  padding: 10px;
}
.magazines-list .item:hover .box {
  opacity: 1;
}
.magazines-list .thumb {
  position: relative;
  display: block;
}
.magazines-list .thumb .box {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  transition: opacity 0.5s;
}
.magazines-list .thumb .wrapper {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}
.magazines-list .thumb .details {
  display: table-cell;
  padding: 15px;
  height: 100%;
  vertical-align: middle;
}
.magazines-list .thumb .details p {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
}
.magazines-list .thumb h2 {
  margin-top: 0;
  font-size: 26px;
  color: #fff;
}
.magazines-list .thumb h2:after {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  border-color: #fff;
}
.magazines-list .thumb .see-more {
  padding-left: 0;
  padding-right: 0;
  width: 65%;
  font-size: 14px;
}
.websites-list {
  text-align: center;
}
.websites-list .header {
  margin-top: 0;
}
.websites-list .description {
  padding-bottom: 190px;
}
.websites-list .counter {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 20px;
  height: 170px;
  background: url(i/tlo_odslony.jpg) no-repeat 50%;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
}
.websites-list .counter .container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
  height: 110px;
}
.websites-list .counter .ru,
.websites-list .counter .pv {
  display: block;
  padding: 10px 0;
}
.websites-list .counter .ru .number,
.websites-list .counter .pv .number {
  display: block;
  font-size: 32px;
  font-weight: 300;
  line-height: 22px;
  color: #767777;
}
.websites-list .counter .ru strong,
.websites-list .counter .pv strong {
  display: block;
  margin-top: 7px;
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  text-transform: uppercase;
  color: #a4a5a5;
}
.websites-list .counter .source {
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 12px;
}
.websites-list .list,
.websites-list .item {
  margin: 0;
  padding: 0;
  list-style: none;
}
.websites-list .list {
  margin-bottom: 15px;
}
.websites-list .item {
  position: relative;
  border-top: 1px solid #C3C4C6;
  margin-top: 15px;
  padding: 10px;
}
.websites-list .item:hover .back {
  opacity: 1;
}
.websites-list .front h2 {
  display: none;
  margin-top: 0;
  font-size: 26px;
}
.websites-list .front h2 img {
  margin-left: 0;
}
.websites-list .front p {
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
}
.websites-list .front img {
  margin: 0 auto;
}
.websites-list .front .social:before {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 35%;
}
.websites-list .front .social-links .fa {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.websites-list .back {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  transition: opacity 0.5s;
}
.websites-list .back .wrapper {
  display: table;
  height: 100%;
  width: 100%;
}
.websites-list .back .details {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.websites-list .back h2 {
  margin: 0 auto;
  font-size: 26px;
  color: #fff;
}
.websites-list .back h2 img {
  margin: 0 auto;
  max-height: 110px;
}
.social-links {
  font-size: 16px;
}
.social-links .fa {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
  border-radius: 50%;
  margin: 0 3px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
}
.social-links .fa-facebook:hover {
  color: #456CB2;
  border-color: #456CB2;
}
.social-links .fa-twitter:hover {
  color: #3BA0FF;
  border-color: #3BA0FF;
}
.social-links .fa-instagram:hover {
  color: #927651;
  border-color: #927651;
}
.magazine-single {
  text-align: center;
}
.magazine-single .header {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.magazine-single .header:after {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.magazine-single img {
  display: inline-block;
}
.magazine-single .social {
  margin-top: 10px;
}
.magazine-single .website-url {
  display: block;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 700;
}
.magazine-single .website-url:hover {
  opacity: 0.7;
}
.magazine-single .description {
  font-size: 14px;
  line-height: 1.8em;
}
.magazine-single .description h1,
.magazine-single .description h2,
.magazine-single .description h3,
.magazine-single .description h4,
.magazine-single .description h5 {
  margin: 10px 0 15px 0;
  text-transform: uppercase;
}
.magazine-single .description h1:after,
.magazine-single .description h2:after,
.magazine-single .description h3:after,
.magazine-single .description h4:after,
.magazine-single .description h5:after {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 40%;
}
.magazine-single .description p {
  padding: 5px 0;
}
.magazine-single .description .editors {
  margin-top: 15px;
  border-top: 1px solid #D8D8D8;
  padding: 15px 0;
}
.magazine-single .description .editors img {
  margin-right: 20px;
  vertical-align: middle;
}
.magazine-single .description .editors p {
  display: inline-block;
}
.magazine-single .btn {
  display: block;
  margin: 0 -15px 15px -15px;
  padding: 20px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 18px;
  background: #be3541;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.magazine-single .btn.linked {
  background: #73c5c3;
}
.magazine-single .btn.special-projects {
  background: #81afe0;
}
.magazine-single .btn:hover {
  opacity: 0.8;
}
.magazine-single .fn {
  margin-top: 15px;
}
.magazine-single .files:hover .list {
  display: block;
}
.magazine-single .files .list {
  display: none;
  margin: -15px -15px 0 -15px;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  padding: 1px 0;
}
.magazine-single .files .list .file {
  display: block;
  padding: 10px 10px;
  text-transform: uppercase;
  font-size: 14px;
  color: #222;
  border-top: #9d9e9e 1px solid;
}
.magazine-single .files .list .file:hover {
  color: #be3541;
}
.magazine-single .files .list .file:first-child {
  border-top: 0;
}
.other-magazines,
.other-websites {
  margin-top: 40px;
  border-top: 1px solid #abacae;
  padding-top: 35px;
}
.other-magazines .header,
.other-websites .header {
  display: none;
}
.other-magazines .list,
.other-websites .list {
  margin: 0;
  padding: 0;
  font-size: 0;
  text-align: center;
}
.other-magazines .list .item,
.other-websites .list .item {
  display: inline-block;
  margin: 0 auto;
  padding: 5px;
  width: 50%;
  font-size: 16px;
  box-sizing: border-box;
}
.other-magazines .list .thumb,
.other-websites .list .thumb {
  display: block;
  overflow: hidden;
  transition: opacity 0.5s;
}
.other-magazines .list .thumb:hover,
.other-magazines .list .thumb.active,
.other-websites .list .thumb:hover,
.other-websites .list .thumb.active {
  opacity: 0.3;
}
.other-websites .list .item {
  margin: 10px auto;
}
.important-events {
  margin-top: 50px;
  text-align: center;
}
.important-events .header {
  margin-top: 0;
}
.important-events .timeline {
  margin-top: 180px;
  margin-bottom: 50px;
  height: 200px;
  border-top: 1px dotted #e22404;
  position: relative;
  padding-left: 30px;
}
.important-events .timeline::before {
  content: '';
  display: block;
  position: absolute;
  top: -13px;
  left: 0;
  background: #fff url(i/timeline-sprite.png) 0px 0px no-repeat;
  width: 15px;
  height: 25px;
}
.important-events .timeline::after {
  content: '';
  display: block;
  position: absolute;
  top: -13px;
  right: 0px;
  background: #fff url(i/timeline-sprite.png) 0px -25px no-repeat;
  width: 20px;
  height: 25px;
}
.important-events .timeline li {
  position: relative;
  float: left;
  min-width: 50px;
  width: 4.5%;
}
.important-events .timeline li .date {
  box-sizing: border-box;
  width: 40px;
  height: 155px;
  padding: 0px;
  margin-top: 20px;
  margin-left: 0px;
  background: #f1f1f2;
  color: #908e8f;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer;
}
.important-events .timeline li .date::before {
  content: '';
  display: block;
  position: absolute;
  top: -35px;
  width: 40px;
  height: 55px;
  background: url(i/timeline-sprite.png) -20px -6px no-repeat;
  text-align: left;
  z-index: 1;
}
.important-events .timeline li .date::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -80px;
  width: 40px;
  height: 80px;
  background: linear-gradient(180deg, #f8f8f9 0%, #ffffff 100%);
}
.important-events .timeline li .date span {
  transform: rotate(-90deg);
  display: block;
  height: 20px;
  width: 150px;
  text-align: right;
  position: relative;
  top: 72px;
  margin-left: -55px;
}
.important-events .timeline li .event {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 400px;
  height: 140px;
  top: -170px;
  left: -30px;
  text-align: left;
  transition: opacity .3s, visibility .2s;
}
.important-events .timeline li:hover .date,
.important-events .timeline li.hover .date {
  background: #ced0d2;
}
.important-events .timeline li:hover .date::before,
.important-events .timeline li.hover .date::before {
  background-position: -60px -6px;
}
.important-events .timeline li:hover .event,
.important-events .timeline li.hover .event {
  visibility: visible;
  opacity: 1;
}
.important-events .timeline li.playboy {
  width: 7%;
}
.important-events .timeline li.cosmopolitan {
  width: 4%;
}
.important-events .timeline li.ckm {
  width: 5.5%;
}
.important-events .timeline li.shape {
  width: 7%;
}
.important-events .timeline li.joy {
  width: 5.5%;
}
.important-events .timeline li.hot {
  width: 5.5%;
}
.important-events .timeline li:nth-child(n+3) .event {
  left: -90px;
}
.important-events .timeline li:nth-child(n+6) .event {
  left: -150px;
}
.important-events .timeline li:nth-child(n+9) .event {
  left: -210px;
}
.important-events .timeline li:nth-child(n+12) .event {
  left: -270px;
}
.important-events .timeline li:nth-child(n+15) .event {
  left: -330px;
}
.subpage {
  position: relative;
  padding-top: 50px;
  padding-bottom: 30px;
}
.subpage.full {
  padding-top: 0;
}
.subpage h1,
.subpage h2,
.subpage h3,
.subpage h4,
.subpage h5 {
  margin-top: 0;
  text-transform: uppercase;
  font-size: 30px;
}
.subpage h1:after,
.subpage h2:after,
.subpage h3:after,
.subpage h4:after,
.subpage h5:after {
  width: 30%;
  min-width: 100px;
}
.full.subpage .header {
  height: 250px;
  line-height: 250px;
  background: url('i/tlo_reklama.jpg') no-repeat 50%;
  background-size: cover;
  text-align: center;
  color: #fff;
}
.full.subpage .header .title {
  display: inline-block;
  line-height: normal;
  font-size: 40px;
}
.full.subpage .header .title:after {
  margin-left: auto;
  margin-right: auto;
  border-color: #fff;
}
.kontakt.subpage .header {
  background-image: url('i/tlo_kontakt.jpg');
}
.projekty-specjalne.subpage .header {
  background-image: url('i/tlo_projekty.jpg');
}
.kariera.subpage .header {
  background-image: url('i/tlo_kariera.jpg');
}
.subpage .header:after {
  display: none;
}
.subpage .content {
  margin-top: 40px;
  line-height: 1.5em;
  font-weight: 400;
  font-size: 17px;
}
.subpage .content h1,
.subpage .content h2,
.subpage .content h3,
.subpage .content h4,
.subpage .content h5 {
  margin: 20px 0;
}
.subpage .content h1:after,
.subpage .content h2:after,
.subpage .content h3:after,
.subpage .content h4:after,
.subpage .content h5:after {
  margin-top: 20px;
}
.subpage .content p {
  padding: 5px 0;
}
.subpage .content table {
  margin: 0 auto;
  width: 100%;
  border-spacing: 0;
}
.subpage .content th,
.subpage .content td {
  border-bottom: 1px solid #D4D4D4;
  padding: 10px;
}
.subpage .content tr:nth-child(even) th,
.subpage .content tr:nth-child(even) td {
  background: #F6F6F6;
}
.subpage .logo {
  display: block;
}
.subpage .logo img {
  max-height: 60px;
}
.subpage.reklama .list {
  margin-top: 15px;
}
.subpage.reklama .list h2 {
  padding: 15px 0;
  background: #B30519;
  color: #fff;
  text-align: center;
}
.subpage.reklama .list h2:after {
  display: none;
}
.subpage.reklama .list .add_regulations,
.subpage.reklama .list .add_pricing {
  text-align: center;
  background: #C0C0C0 ;
  font-size: 20px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  transition: all 0.7s;
}
.subpage.reklama .list .add_regulations:hover,
.subpage.reklama .list .add_pricing:hover {
  background: #B30519;
}
.subpage.reklama .list .item {
  padding: 20px 0;
  margin: 5px 0;
  background: #F6F6F6;
  text-align: center;
  transition: background 0.5s;
}
.subpage.reklama .list .item a {
  display: block;
}
.subpage.reklama .list .item p {
  display: none;
}
.subpage.reklama .list .item:hover {
  background: #A8A9AD;
  color: #fff;
}
.subpage.reklama .websites-adv h2 {
  background: #66cbcb;
}
.subpage.reklama .websites-adv .add_regulations:hover,
.subpage.reklama .websites-adv .add_pricing:hover {
  background: #66cbcb;
}
.subpage.kontakt .row {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D8D8D8;
  text-align: center;
}
.subpage.kontakt .row.management > .row {
  border-bottom: none;
  padding-bottom: 0px;
}
.subpage.kontakt .row.management > .row .col p {
  line-height: 1.2em;
}
.subpage.kontakt .row.management > .row .col .person {
  font-family: 'Playfair Display', serif;
  font-size: 19px;
  color: #5a5b5e;
}
.subpage.kontakt .row.management > .row .col .position {
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  color: #adafb2;
}
.subpage.kontakt .row h4:after {
  margin-left: auto;
  margin-right: auto;
}
.subpage.kontakt .row:last-child {
  margin-bottom: 0;
  border: none;
  padding-bottom: 0;
}
.subpage.kontakt .col {
  margin-bottom: 15px;
}
.subpage.projekty-specjalne .content {
  text-align: justify;
}
.addlayer {
  display: none;
  min-width: 80% !important;
}
.addlayer .wrapper {
  background: #fff;
}
.addlayer .b-close {
  position: absolute;
  display: inline-block;
  top: -15px;
  right: -15px;
  padding: 5px;
  width: 25px;
  height: 25px;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  background: #000;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.addlayer .b-ajax-wrapper {
  padding: 10px;
}
.ext-widget {
  margin-top: 20px;
}
.ext-widget iframe {
  border: none;
  width: 100%;
}
/*
    Author     : rkiedrowski
*/
.article-list .header {
  margin-top: 0;
}
.article-list .list .item {
  margin-bottom: 20px;
  border-bottom: 1px solid #b4b5b7;
  padding-bottom: 20px;
}
.article-list .list .item:last-child {
  border: none;
}
.article-list .list a {
  display: block;
}
.article-list .list a:hover img {
  opacity: 0.5;
  transition: opacity 0.5s;
}
.article-list .list h2 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2em;
}
.article-list .list h2:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
  margin-top: 10px;
  width: 25vw;
}
.article-list .list .description {
  font-size: 14px;
}
.article-single .header,
.subpage:not(.full) .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}
.article-single .header:after,
.subpage:not(.full) .title:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
  width: 25%;
}
.article-single .thumb {
  margin-bottom: 30px;
}
.article-single .lead {
  margin-bottom: 15px;
  font-weight: bold;
}
.article-single .content {
  line-height: 1.6em;
  font-weight: 400;
  font-size: 17px;
}
.article-single .content p {
  padding: 5px 0;
}
/*
    Author     : rkiedrowski
*/
.video-list {
  text-align: center;
  padding-bottom: 10px;
}
.video-list .header {
  margin-top: 0;
}
.video-list .list .item {
  margin: 0 auto 1em auto;
  text-align: left;
}
.video-list .list .item:hover h2 {
  color: #fff;
  background: #000;
}
.video-list .list .item:hover h2:before {
  position: absolute;
  left: 0;
  top: -4px;
  content: "";
  display: inline-block;
  width: 100%;
  height: 4px;
  background: #be3541;
}
.video-list .list .thumb {
  position: relative;
}
.video-list .list .thumb:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -38px 0 0 -38px;
  width: 76px;
  height: 76px;
  background: url(i/video_ico.png) no-repeat;
}
.video-list .list h2 {
  position: relative;
  margin: 0;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2em;
  transition: background-color 0.5s;
}
.video-single {
  padding-bottom: 25px;
}
.video-single .header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}
.video-single .header:after {
  content: " ";
  display: block;
  margin-top: 3px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 2px;
  width: 25%;
}
.video-single .thumb {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}
.video-single .thumb iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-single .content {
  line-height: 1.5em;
}
.video-single .content p {
  padding: 5px 0;
}
/*
    Author     : rkiedrowski
*/
.studio-foto-page .page-content {
  padding-top: 0px;
  font-family: 'Roboto Slab', serif;
  color: #5D2701;
  font-weight: 300;
}
.studio-foto-page .page-footer {
  margin-top: 0px;
}
.studio-foto-menu .navigation {
  padding: 30px 0;
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
}
.studio-foto-menu .navigation a {
  display: inline-block;
  border-left: 1px solid #5D2701;
  padding-left: 9px;
  margin-left: 7px;
  color: #5D2701;
  font-size: 15pt;
  font-weight: 300;
  line-height: 15pt;
  text-transform: uppercase;
}
.studio-foto-menu .navigation a:first-child {
  margin: 0;
  border: 0;
  padding: 0;
}
.studio-foto-menu .navigation a.selected {
  color: #77207E;
  font-weight: 700;
}
.studio-foto-container {
  padding-top: 340px;
  height: 857px;
  box-sizing: border-box;
  background: #fff no-repeat center top;
}
.studio-foto-container .content {
  position: relative;
  margin: 0 auto;
  max-width: 638px;
  height: 341px;
  text-align: center;
  font-size: 18pt;
  line-height: 30pt;
}
.studio-foto-container h1 {
  margin: 15px 0;
  text-transform: uppercase;
  color: #77207E;
  font-size: 46pt;
  font-weight: 100;
  font-family: 'Roboto Slab', serif;
  line-height: initial;
}
.studio-foto-container.strefa-flash {
  background-image: url('i/sf/main_bg.jpg');
}
.studio-foto-container.strefa-flash h1 {
  margin: 0;
  position: relative;
  top: 44px;
  font-size: 72pt;
}
.studio-foto-container.strefa-flash h2 {
  position: absolute;
  left: 0;
  top: 160px;
  width: 100%;
  text-transform: none;
  color: #000;
  font-size: 15.6pt;
  font-weight: 700;
  line-height: 13pt;
}
.studio-foto-container.strefa-flash-design {
  background-image: url('i/sf/designe_bg.jpg');
}
.studio-foto-container.strefa-flash-product-story {
  background-image: url('i/sf/product_bg.jpg');
}
.studio-foto-container.strefa-flash-corpo-story {
  background-image: url('i/sf/corpo_bg.jpg');
}
.studio-foto-container.strefa-flash-home-made-story {
  background-image: url('i/sf/home_made_bg.jpg');
}
.studio-foto-container.strefa-flash-kontakt {
  background-image: url('i/sf/contact_bg.jpg');
}
/*
    Author     : rkiedrowski
*/
.application-form-box {
  max-width: 800px;
  margin: 50px auto 20px;
}
.application-form-box .recrutation-info {
  text-align: center;
  margin: 20px auto;
  font-size: 1.1em;
}
.application-form-box .recrutation-info p {
  margin-bottom: 5px;
}
#application-form {
  margin: 40px auto 20px;
  font-size: 16px;
}
#application-form label {
  text-align: center;
  text-transform: none;
  font-size: 1.1em;
}
#application-form .hint {
  font-size: 14px;
  color: #7c7c7c;
}
#application-form .form-errors {
  margin: 5px auto;
}
#application-form .item-text {
  max-width: 400px;
  margin: auto;
  margin-bottom: 20px;
}
#application-form .item-text input {
  width: 400px;
  max-width: 100%;
  border: 0;
  border-bottom: 1px dotted #888;
  font-size: 18px;
}
#application-form .item-file {
  max-width: 400px;
  margin: 20px auto;
}
#application-form .item-file .label {
  padding-bottom: 1px;
  margin-bottom: 1px;
}
#application-form .item-file .button {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 34px;
  color: #4e504f;
  background: #e6e6e6;
  border: 1px solid #bbb;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 34px;
  font-size: 1.1em;
}
#application-form .item-file .button:hover {
  background: #bbb;
}
#application-form .item-file .button:active {
  background: #aaa;
}
#application-form .item-file .button input {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
#application-form .item-file .file-info {
  width: 100%;
  height: 40px;
  overflow: hidden;
  margin-top: 10px;
  color: #4e504f;
  background: #f4f4f4;
  text-align: center;
  text-transform: none;
  line-height: 40px;
  font-size: 16px;
}
#application-form .item-checkbox.required {
  color: #c51f22;
}
#application-form .item-checkbox label {
  display: inline-block;
  padding-left: 20px;
  width: auto;
  font-size: 1.0em;
  text-align: left;
}
#application-form .item-checkbox label a {
  text-decoration: underline;
}
#application-form .item-checkbox input {
  position: absolute;
  margin-top: 8px;
}
#application-form .item-submit {
  margin-top: 20px;
}
#application-form .item-submit input {
  display: block;
  width: 300px;
  height: 50px;
  margin: 20px auto;
  color: #fff;
  background: #c51f22;
  border: 1px solid #c50a0d;
  text-transform: uppercase;
  font-size: 28px;
  cursor: pointer;
}
#application-form .item-submit input:hover {
  background: #c50a0d;
}
#application-form .item-submit input:active {
  background: #c50004;
}
#application-form #data-processing-info {
  margin: 35px auto 5px;
  font-size: 0.9em;
  text-align: justify;
}
.job-offers h2 {
  text-transform: uppercase;
  font-size: 1.2em;
}
.job-offers .job-offer-list {
  margin-top: 10px;
}
.job-offers .job-offer-list li {
  text-align: center;
}
.job-offers .job-offer-list li a {
  box-sizing: border-box;
  display: block;
  height: 150px;
  margin: 20px 6px;
  padding: 25px;
  border: 2px solid #e4e4e4;
  text-transform: uppercase;
}
.job-offers .job-offer-list li a .title {
  font-family: 'Lato', sans-serif;
  font-size: 1.1em;
  color: #323232;
}
.job-offers .job-offer-list li a .title::after {
  content: " ";
  display: block;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #666666;
  height: 2px;
  width: 80px;
  margin: 15px auto;
}
.job-offers .job-offer-list li a .department {
  color: #6b6c70;
}
.job-offers .job-offer-list li a:hover {
  background: #bd292c;
}
.job-offers .job-offer-list li a:hover .title {
  color: #fff;
}
.job-offers .job-offer-list li a:hover .title::after {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.job-offers .job-offer-list li a:hover .department {
  color: #fff;
}
.job-offer {
  margin: auto;
}
.job-offer .title {
  font-family: 'Lato', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
  text-align: center;
}
.job-offer .title::after {
  content: " ";
  display: block;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #666666;
  height: 2px;
  width: 80px;
  margin: 20px auto;
}
.job-offer .department {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
  text-align: center;
}
.job-offer .content {
  max-width: 680px;
  margin: auto;
  color: #7b7b7b;
  font-size: 15px;
  line-height: 1.5em;
}
.job-offer .content .description,
.job-offer .content .requirements {
  margin-top: 50px;
}
.job-offer .content .description h3,
.job-offer .content .requirements h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
}
.job-offer .content .description li,
.job-offer .content .requirements li {
  list-style: disc;
  margin-left: 20px;
}
@media screen and (max-width: 480px) {
  .ext-widget .widget-voyage {
    height: 57vw;
  }
  .studio-foto-menu .navigation a {
    display: block;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }
  .studio-foto-container h1 {
    font-size: 30pt;
    font-weight: 300;
  }
}
@media screen and (max-width: 400px) {
  .studio-foto-container h1 {
    font-size: 28pt;
    font-weight: 300;
  }
  .studio-foto-container p {
    font-size: 17pt;
  }
}
@media screen and (max-width: 360px) {
  .studio-foto-container .content {
    line-height: 26pt;
  }
  .studio-foto-container h1 {
    font-size: 24pt;
    font-weight: 300;
  }
  .studio-foto-container p {
    font-size: 15pt;
  }
  .studio-foto-container.strefa-flash h1 {
    top: 64px;
    font-size: 62pt;
  }
  .studio-foto-container.strefa-flash h2 {
    top: 160px;
    font-size: 13.5pt;
  }
}
@media screen and (min-width: 480px) {
  .magazines-list .list,
  .websites-list .list {
    font-size: 0;
  }
  .magazines-list .item,
  .websites-list .item {
    display: inline-block;
    width: 33.33%;
    box-sizing: border-box;
    font-size: 16px;
    vertical-align: top;
  }
  .magazines-list .thumb,
  .websites-list .thumb {
    display: block;
    overflow: hidden;
  }
  .websites-list .list {
    margin: 50px auto 0 auto;
    text-align: left;
  }
  .websites-list .item {
    position: relative;
    border: none;
    margin: 1%;
    height: 56vw;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    width: 31.33%;
  }
  .websites-list .thumb {
    height: 25vw;
  }
  .websites-list .front .details {
    margin-top: 2%;
  }
  .websites-list .social {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
  .other-magazines .list .item,
  .other-websites .list .item {
    width: 33.33%;
  }
  .video-list .list {
    font-size: 0;
  }
  .video-list .list .item {
    display: inline-block;
    padding: 5px;
    margin-bottom: 0.5em;
    width: 50%;
    height: 40vw;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 16px;
  }
  .subpage.reklama .list {
    font-size: 0;
  }
  .subpage.reklama .list .no-border {
    position: relative;
  }
  .subpage.reklama .list .no-border:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-top: 0px solid #B30519;
  }
  .subpage.reklama .list .add_pricing {
    position: relative;
  }
  .subpage.reklama .list .add_pricing:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-top: 0px solid #B30519;
  }
  .subpage.reklama .list h2 {
    position: relative;
  }
  .subpage.reklama .list h2:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #B30519;
  }
  .subpage.reklama .list ul {
    margin-top: -10px;
  }
  .subpage.reklama .list .item {
    display: inline-block;
    width: 50%;
    font-size: 16px;
    box-sizing: border-box;
  }
  .subpage.reklama .list .item:nth-child(2n+1) {
    border-right: 5px solid #fff;
  }
  .subpage.reklama .list .item:nth-child(2n+2) {
    border-left: 5px solid #fff;
  }
  .subpage.reklama .websites-adv h2:before {
    border-top-color: #66cbcb;
  }
}
@media screen and (min-width: 640px) {
  .slider .item h3 {
    font-size: 30px;
  }
  .slider .item .prev,
  .slider .item .next {
    font-size: 50px;
  }
  .news .content {
    display: table;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    font-size: 0;
  }
  .news .content .item {
    display: table-cell;
    width: 33.33%;
    box-sizing: border-box;
    font-size: 16px;
    vertical-align: top;
  }
  .news .content h3:after {
    width: 10vw;
  }
  .js .gmap {
    height: 480px;
  }
  .websites-list .item {
    height: 50vw;
  }
  .websites-list .thumb {
    height: 27vw;
  }
  .video-widget .list {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .video-widget .list .item {
    display: table-cell;
    padding: 0 5px;
    width: 33.33%;
  }
  .video-widget .list h3 {
    font-size: 16px;
  }
  .article-list .list {
    padding-top: 20px;
  }
  .article-list .list .item {
    border: none;
  }
  .article-list .list h2 {
    margin-top: 0;
  }
  .article-list .list .details {
    float: left;
    width: 60%;
  }
  .article-list .thumb {
    float: left;
    padding-right: 20px;
    width: 40%;
    box-sizing: border-box;
  }
  .video-list .list .item {
    height: 38vw;
  }
  .websites-list .counter .container {
    padding-top: 40px;
    height: 90px;
  }
  .websites-list .counter .ru,
  .websites-list .counter .pv {
    display: inline-block;
    padding: 0 30px;
  }
  .websites-list .counter .ru .number,
  .websites-list .counter .pv .number {
    font-size: 50px;
    line-height: 40px;
  }
}
@media screen and (min-width: 768px) {
  .breadcrubs {
    display: block;
  }
  .common-header .mobile-btn {
    display: none;
  }
  .common-header .navigation-box {
    display: block;
    position: static;
    float: right;
    width: auto;
  }
  .common-header .navigation {
    float: left;
  }
  .common-header .navigation .item {
    display: inline-block;
    padding: 17px 10px;
    font-size: 12px;
    /*&:hover {
                    .submenu {
                        opacity: 1;
                        height: auto;
                        z-index: 100;
                    }
                }*/
  }
  .common-header .search-form {
    float: right;
    margin-top: 15px;
  }
  .slider .item h3 {
    font-size: 35px;
  }
  .about .header .title,
  .news .header .title,
  .magazines-list .header .title,
  .websites-list .header .title,
  .other-magazines .header .title,
  .important-events .header .title,
  .article-list .header .title,
  .video-list .header .title,
  .other-websites .header .title {
    font-size: 35px;
  }
  .about .content {
    columns: 2;
    column-gap: 60px;
  }
  .magazines .header,
  .websites .header,
  .special-projects .header,
  .studio-foto .header {
    font-size: 30px;
  }
  .video .header .title:after {
    width: 10vw;
  }
  .magazines-list .item {
    width: 33.33%;
  }
  .websites-list .item {
    height: 42vw;
  }
  .magazine-single .header {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 20px;
    width: 50%;
  }
  .magazine-single .header:after {
    margin-left: 0;
    margin-top: 20px;
    width: 40%;
  }
  .magazine-single .description {
    font-weight: 400;
  }
  .magazine-single .description h1:after,
  .magazine-single .description h2:after,
  .magazine-single .description h3:after,
  .magazine-single .description h4:after,
  .magazine-single .description h5:after {
    margin-left: 0;
    width: 20%;
  }
  .magazine-single .column {
    float: left;
    width: 55%;
    box-sizing: border-box;
    text-align: left;
  }
  .magazine-single .column:first-of-type {
    padding-right: 20px;
    width: 45%;
    text-align: center;
  }
  .magazine-single .btns {
    clear: both;
    padding-top: 20px;
  }
  .magazine-single .btn {
    margin-left: 0;
    margin-right: 0;
  }
  .magazine-single .files .list {
    margin-left: 0;
    margin-right: 0;
  }
  .magazine-single .website-url {
    padding: 20px 0;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
  }
  .other-magazines .item,
  .other-websites .item {
    width: 25%;
  }
  .other-websites .item {
    padding: 10px;
  }
  .article-single {
    margin: 0 auto;
    width: 700px;
  }
  .article-single .header,
  .subpage:not(.full) .title {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.2em;
  }
  .article-single .header:after,
  .subpage:not(.full) .title:after {
    margin-top: 25px;
  }
  .video-list .list .item {
    width: 33.33%;
    height: 27vw;
  }
  .video-single {
    margin: 0 auto;
    width: 700px;
  }
  .video-single .header {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.2em;
  }
  .video-single .header:after {
    margin-top: 25px;
  }
  .projekty-specjalne .content {
    columns: 2;
    column-gap: 60px;
  }
  .kariera .content {
    columns: 2;
    column-gap: 60px;
  }
  .job-offers .job-offer-list li {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin: 0 -0.1em;
  }
}
@media screen and (max-width: 980px) {
  .important-events {
    margin-bottom: 50px;
  }
  .important-events .timeline {
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    border-top: none;
    border-left: 1px dotted #e22404;
    padding-top: 10px;
    padding-left: 0px;
    margin-left: 12px;
  }
  .important-events .timeline::before {
    top: -13px;
    left: -5px;
  }
  .important-events .timeline::after {
    top: auto;
    right: auto;
    left: -10px;
    bottom: -20px;
    transform: rotate(90deg);
  }
  .important-events .timeline li {
    position: relative;
    float: none;
    width: 100% !important;
  }
  .important-events .timeline li .date {
    width: auto;
    height: 30px;
    padding: 0px;
    margin-top: 10px;
    margin-left: 50px;
    background: none !important;
  }
  .important-events .timeline li .date::before {
    transform: rotate(90deg);
    top: -16px;
    left: -13px;
  }
  .important-events .timeline li .date::after {
    display: none;
  }
  .important-events .timeline li .date span {
    position: static;
    transform: rotate(0deg);
    height: auto;
    width: auto;
    margin: auto;
    text-align: left;
  }
  .important-events .timeline li .event {
    visibility: visible;
    opacity: 1;
    position: static;
    width: auto;
    max-width: 100%;
    transition: none;
  }
}
@media screen and (min-width: 980px) {
  .page-header.fixed-active {
    position: fixed;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E3E3;
    width: 100%;
    background: #fff;
    z-index: 1000;
  }
  .page-header.fixed-active .common-header {
    margin-bottom: 0;
    height: 40px;
  }
  .page-header.fixed-active .logo {
    width: 201px;
    max-width: none;
    height: 40px;
    background: url(i/logo-nmw.png) no-repeat center;
    background-size: contain;
  }
  .page-header.fixed-active .logo img {
    display: none;
  }
  .page-header.fixed-active .navigation .item {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .common-header .navigation .item {
    padding: 22px 15px;
    font-size: 13px;
  }
  .common-header .search-form {
    margin-top: 20px;
  }
  .page-footer {
    margin-top: 80px;
  }
  .page-footer .common-footer {
    padding: 0;
  }
  .page-footer .wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .page-footer .logo {
    display: table-cell;
    width: 15%;
  }
  .page-footer .navigation-box {
    display: table-cell;
    width: 85%;
    vertical-align: middle;
    text-align: right;
  }
  .slider .item h3 {
    font-size: 40px;
  }
  .slider .item h3:after {
    width: 40%;
  }
  .slider .item p {
    font-size: 19px;
  }
  .slider .item .prev,
  .slider .item .next {
    font-size: 60px;
  }
  .about .header .title,
  .news .header .title,
  .magazines-list .header .title,
  .websites-list .header .title,
  .other-magazines .header .title,
  .important-events .header .title,
  .article-list .header .title,
  .video-list .header .title,
  .other-websites .header .title {
    font-size: 40px;
  }
  .about .contact-info,
  .subpage .contact-info {
    display: block;
    float: left;
    margin: 0 40px 0 15px;
    text-align: left;
    font-size: 14px;
  }
  .about .contact-info h3,
  .subpage .contact-info h3 {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 23px;
  }
  .about .contact-info h3:after,
  .subpage .contact-info h3:after {
    content: " ";
    display: block;
    margin-top: 3px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 2px;
    margin-top: 20px;
  }
  .about .photos {
    display: block;
    clear: both;
    margin-top: 30px;
    transition: 0.5s margin;
  }
  .about .photos .item {
    display: inline-block;
    padding: 0 30px;
    margin: 0;
    width: 20%;
    box-sizing: border-box;
    text-align: center;
    transition: 0.5s padding;
    font-size: 13px;
  }
  .about .photos .item .thumb {
    margin: 0 auto 10px auto;
    width: 136px;
    height: 136px;
    transition: 0.5s width, 0.5s height;
  }
  .about .photos .item .thumb img {
    border-radius: 50%;
  }
  .about .photos .item em {
    font-style: normal;
    display: block;
    color: #9E9E9E;
  }
  .about .content {
    font-size: 16px;
  }
  .about .see-more {
    float: right;
    margin-right: 15px;
  }
  .magazines,
  .websites,
  .special-projects,
  .studio-foto {
    position: relative;
    text-align: left;
  }
  .magazines .header,
  .websites .header,
  .special-projects .header,
  .studio-foto .header {
    font-size: 40px;
  }
  .magazines .content,
  .websites .content,
  .special-projects .content,
  .studio-foto .content {
    display: block;
    height: 40px;
  }
  .magazines .see-more,
  .websites .see-more,
  .special-projects .see-more,
  .studio-foto .see-more {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -20px;
  }
  .news {
    padding-bottom: 0;
  }
  .news .content {
    width: 100%;
    max-width: 980px;
  }
  .news .content .item {
    width: 326px;
  }
  .news .content .item h3 {
    font-size: 25px;
  }
  .news .see-more {
    float: right;
    margin-right: calc((100% - 3*326px)/2);
  }
  .video {
    display: table;
    table-layout: fixed;
    margin-top: 40px;
    width: 100%;
  }
  .video .header {
    display: table-cell;
    margin-top: 0;
    padding: 20px 20px 20px 0;
    margin-right: 1vw;
    width: 25%;
    text-align: right;
    vertical-align: middle;
  }
  .video .header .title {
    display: block;
    float: right;
    padding-left: 20%;
  }
  .video .header .title:after {
    margin-right: 0;
    width: 30%;
  }
  .video .header .see-more,
  .video-widget .header .see-more {
    display: inline-block;
  }
  .video .content {
    display: table-cell;
    padding: 0 10px;
    width: 75%;
    font-size: 0;
    vertical-align: top;
  }
  .video .content .item {
    float: left;
    padding: 20px 10px 0 10px;
    margin: 0;
    width: 33.33%;
    font-size: 16px;
    box-sizing: border-box;
  }
  .video .see-more,
  .video-widget .see-more {
    display: none;
  }
  .video-widget .container {
    position: relative;
  }
  .video-widget .header {
    position: absolute;
    top: 25%;
    padding: 0;
    width: 15%;
    text-align: center;
    font-size: 30px;
    box-sizing: border-box;
  }
  .video-widget .list {
    float: right;
    box-sizing: border-box;
    width: 85%;
  }
  .video-widget .list .item {
    padding: 0 10px;
  }
  .video-widget .list h3 {
    font-size: 18px;
  }
  .magazines-list .item {
    width: 16.66%;
  }
  .websites-list .item {
    height: 190px;
  }
  .websites-list .front {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .websites-list .front h2 {
    display: block;
    margin-bottom: 10px;
  }
  .websites-list .front .social {
    left: 5px;
  }
  .websites-list .front .social:before {
    margin-left: 0;
  }
  .websites-list .front .thumb,
  .websites-list .front .details {
    display: table-cell;
    margin: 0;
    width: 50%;
    height: auto;
    vertical-align: middle;
  }
  .websites-list .front .thumb {
    vertical-align: middle;
  }
  .websites-list .front .details {
    position: relative;
    text-align: left;
  }
  .websites-list .counter .ru .number,
  .websites-list .counter .pv .number {
    font-size: 70px;
    line-height: 60px;
  }
  .websites-list .counter .ru strong,
  .websites-list .counter .pv strong {
    font-size: 13px;
    line-height: 13px;
  }
  .magazine-single {
    position: relative;
  }
  .magazine-single .column {
    width: 60%;
  }
  .magazine-single .column:first-of-type {
    padding-right: 30px;
    width: 40%;
  }
  .magazine-single .description .columns {
    padding-bottom: 5px;
    column-count: 2;
    column-width: 40px;
  }
  .magazine-single .description .columns p {
    break-inside: avoid;
  }
  .magazine-single .btns {
    float: right;
    clear: none;
    width: 60%;
  }
  .magazine-single .btn {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 78px;
    line-height: 78px;
    box-sizing: border-box;
  }
  .magazine-single .fn {
    display: table;
    table-layout: fixed;
    margin-bottom: 15px;
    width: 100%;
  }
  .magazine-single .fn .item {
    display: table-cell;
    position: relative;
    border-right: 8px solid #fff;
    vertical-align: middle;
  }
  .magazine-single .fn .item .btn {
    display: table-cell;
    line-height: normal;
    vertical-align: middle;
  }
  .magazine-single .fn .item:first-child {
    border-left: 0;
  }
  .magazine-single .fn .item:last-child {
    border-right: 0;
  }
  .magazine-single .fn .item.files {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }
  .magazine-single .fn .btn {
    padding: 0 12px;
    font-size: 16px;
    line-height: normal;
  }
  .magazine-single .social-links {
    padding-top: 10px;
  }
  .magazine-single .social-links .fa {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
  }
  .magazine-single .files .list {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    width: 100%;
    background: #f2f2f2;
    z-index: 10;
    text-align: left;
  }
  .magazine-single .files .btn {
    position: relative;
    padding-left: 35px !important;
    text-align: left;
  }
  .magazine-single .files .btn:before {
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -8px;
    content: "\f019";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    font-smoothing: antialiased;
  }
  .website-single.magazine-single .files .btn:before {
    position: static;
    margin: 0 10px 0 0;
  }
  .website-single.magazine-single .files .btn {
    padding-left: 15px !important;
    text-align: center;
  }
  .other-magazines .list,
  .other-websites .list {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .other-magazines .list .item,
  .other-websites .list .item {
    display: table-cell;
    width: auto;
    vertical-align: top;
  }
  .other-websites .list {
    display: block;
  }
  .other-websites .list .item {
    display: inline-block;
    width: 20%;
  }
  .subpage {
    /*        .logo {
            display: block;
            position: absolute;
            top: 60px;
            right: @cpadding_wide;
            max-width: 200px;
        }*/
  }
  .full.subpage .header {
    height: 350px;
    line-height: 350px;
  }
  .subpage .content .columns {
    column-count: 2;
    column-width: 40px;
  }
  .subpage .content .columns p {
    break-inside: avoid;
  }
  .subpage.reklama .list {
    float: left;
    margin-top: 50px;
    width: 50%;
    box-sizing: border-box;
  }
  .subpage.reklama .list ul {
    margin-top: -13px;
  }
  .subpage.reklama .list .item {
    margin: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    font-weight: 400;
    vertical-align: middle;
  }
  .subpage.reklama .list .item:nth-child(2n+1) {
    border-right-width: 10px;
  }
  .subpage.reklama .list .item:nth-child(2n+2) {
    border-left-width: 10px;
  }
  .subpage.reklama .list .item p {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
  }
  .subpage.reklama .magazines-adv {
    padding-right: 10px;
  }
  .subpage.reklama .websites-adv {
    padding-left: 10px;
  }
  .subpage.kontakt .row {
    margin-top: 70px;
    margin-bottom: 70px;
    padding-bottom: 70px;
    font-size: 0;
  }
  .subpage.kontakt .row h4 {
    font-size: 18px;
  }
  .subpage.kontakt .row:last-child {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .subpage.kontakt .row.management {
    margin-top: -10px;
  }
  .subpage.kontakt .row.management > .row {
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .subpage.kontakt .row.management > .row .col {
    border-left: 2px solid #d8d8d8;
  }
  .subpage.kontakt .row.management > .row .col:first-child {
    border-left: none;
  }
  .subpage.kontakt .col {
    display: inline-block;
    min-width: 10%;
    margin: 0;
    padding: 0 20px;
    font-size: 16px;
    vertical-align: top;
    box-sizing: border-box;
  }
  .subpage.kontakt .row-3 .col {
    width: 33%;
  }
  .subpage.kontakt .row-4 .col {
    width: 25%;
  }
  .subpage.kontakt .row-5 .col {
    width: 20%;
  }
  .subpage.kontakt .row-6 .col {
    width: 16.66%;
  }
  .subpage.kontakt .row-7 .col {
    width: 14.285%;
  }
  .subpage.kontakt .row-8 .col {
    width: 12.5%;
  }
  .article-list .list {
    padding-top: 30px;
  }
  .article-list .list .details {
    width: 65%;
    font-size: 16px;
  }
  .article-list .thumb {
    padding-right: 30px;
    width: 35%;
  }
  .video-list .list .item {
    width: 25%;
    height: 232px;
  }
  .cookie-policy-box {
    width: 900px;
    margin-left: -450px;
  }
  .job-offers .job-offer-list li {
    width: 33.3%;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 1170px;
  }
  .container-wide {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }
  .slider {
    /*
        .bx-viewport {
            height: 448px !important;
        }*/
  }
  .slider .item {
    position: relative;
  }
  .slider .item .wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
  }
  .slider .item .thumb {
    height: 447px;
    background-size: cover;
    background-position: 50%;
  }
  .js .slider .item .thumb .image {
    display: none;
  }
  .slider .item .content {
    display: table;
    position: absolute;
    right: 55px;
    width: 100vw;
    height: 100%;
    right: 0;
    box-sizing: border-box;
    background: transparent;
  }
  .slider .item .content .box {
    display: table-cell;
    vertical-align: middle;
  }
  .slider .item p {
    padding: 0;
  }
  .slider .item .prev,
  .slider .item .next {
    position: static;
    margin-top: 20px;
    padding: 0 10px;
    font-size: 50px;
  }
  .slider .item:nth-child(2) .content {
    background: transparent;
  }
  .about {
    min-width: none !important;
  }
  .about .contact-info,
  .subpage .contact-info {
    margin-left: 55px;
  }
  .about .photos {
    margin-top: 50px;
  }
  .about .photos .item {
    padding: 0 40px;
  }
  .about .photos .item .thumb {
    width: 176px;
    height: 176px;
  }
  .about .see-more {
    margin-right: 55px;
  }
  .magazines .header,
  .websites .header,
  .special-projects .header,
  .studio-foto .header {
    font-size: 45px;
  }
  .magazines .see-more,
  .websites .see-more,
  .special-projects .see-more,
  .studio-foto .see-more {
    right: 55px;
  }
  .video .header {
    border-right-width: 12px;
  }
  .video .header .title {
    padding-left: 40%;
  }
  .video-widget {
    padding-left: 0;
    padding-right: 0;
  }
  .video-widget .header {
    padding: 0 20px;
    font-size: 35px;
  }
  .video-widget .list h3 {
    font-size: 20px;
  }
  /*    .magazines-list {
        .item {
            width: 20%;
        }
    }*/
  .websites-list .item {
    height: 200px;
  }
  .websites-list .counter .container {
    padding-top: 30px;
    padding-bottom: 40px;
    height: 100px;
  }
  .websites-list .counter .source {
    right: 55px;
  }
  .websites-list .counter .ru,
  .websites-list .counter .pv {
    padding: 0 40px;
  }
  .websites-list .counter .ru .number,
  .websites-list .counter .pv .number {
    font-size: 82px;
    line-height: 72px;
  }
  .websites-list .counter .ru strong,
  .websites-list .counter .pv strong {
    font-size: 17px;
    line-height: 17px;
  }
  .social-links .fa {
    margin: 0 5px;
  }
  .magazine-single .btn {
    font-size: 20px;
  }
  .magazine-single .fn .btn {
    padding: 15px;
    font-size: 19px;
  }
  .magazine-single .editors {
    width: 60%;
  }
  .website-single.magazine-single .website-url {
    margin-top: 30px;
  }
  .article-list .thumb {
    padding-right: 40px;
  }
  .article-list .list {
    padding-top: 40px;
  }
  .article-list .list h2 {
    font-size: 24px;
  }
  .article-single {
    width: 700px;
  }
  .article-single .header,
  .subpage:not(.full) .title {
    font-size: 43px;
  }
  .video-single {
    width: 700px;
  }
  .video-single .header {
    font-size: 26px;
  }
  .full.subpage .header {
    height: 450px;
    line-height: 450px;
  }
}

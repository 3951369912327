/*
    Author     : rkiedrowski
*/

.article-list {
    .header {
        &:extend(.about .header all);
        margin-top: 0;
    }
    .list {
        .item {
            margin-bottom: 20px;
            border-bottom: 1px solid #b4b5b7;
            padding-bottom: 20px;
            &:last-child {
                border: none;
            }
        }
        a {
            display: block;
            &:hover {
                img {
                    opacity: 0.5;
                    transition: opacity 0.5s;
                }
                .see-more {
                    &:extend(.see-more-btn:hover);
                }
            }
        }
        h2 {
            margin-bottom: 10px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.2em;
            &:after {
                .mx-header-underscore;
                margin-top: 10px;
                width: 25vw;
            }
        }
        .description {
            font-size: 14px;
        }
        .see-more {
            &:extend(.see-more-btn all);
        }
    }
}

.article-single {
    .header {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        &:after {
            .mx-header-underscore;
            width: 25%;
        }
    }
    .thumb {
        margin-bottom: 30px;
    }
    .lead {
        margin-bottom: 15px;
        font-weight: bold;
    }
    .content {
        line-height: 1.6em;
        font-weight: @fw_default;
        font-size: 17px;
        p {
            padding: 5px 0;
        }
    }
}
body {
    padding: 15px 0 0;
    margin: 0;
    background: #fff;
    font-family: @ff_default;
    font-size: @fs_default;
    color: #8c8c8c;
}

.common-header {
    position: relative;
    margin-bottom: 5px;
    h1 {
        display: inline-block;
        margin: 0;
    }
    .logo {
        display: inline-block;
        max-width: 18vw;
        transition: 0.5s;
    }
    .menu-btn {
        float: right;
        padding: 0;
        border: 0;
        margin: -5px 0 0;
        color: #B4B5B7;
        font-size: 10vw;
        background: none;
        outline: 0;
    }
    .navigation-box {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 75%;
        background: #fff;
        z-index: 100;
    }
    .navigation {
        .item {
            position: relative;
            display: block;
            padding: 15px;
            transition: 0.5s;
            &.selected {
                .label {
                    color: @r_color;
                }
            }
            .link {
                &:hover {
                    .label {
                        color: @r_color;
                    }
                }
            }
            .label {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 13px;
            }
        }
        .submenu {
            display: none;
            position: absolute;
            left: -20px;
            top: 100%;
            padding: 0 30px 20px 30px;
            background: #fff;
            z-index: 200;
            box-shadow: 0 7px 11px rgba(0, 0, 0, 0.2);
            .submenu-label {
                display: block;
                position: relative;
                padding: 10px 50px 10px 0;
                white-space: nowrap;
                border-bottom: 1px solid #eee;
                text-align: left;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    display: block;
                    border-bottom: 3px solid transparent;
                    width: 100%;
                }
                &:hover {
                    color: @r_color;
                    &:after {
                        border-color: #000;
                    }
                }
            }
        }
    }
    .search-form {
        display: none;
        .fa-search {
            display: none;
            transition: 0.5s;
            .js & {
                display: inline-block;
            }
        }
        .input-submit {
            .js & {
                display: none;
            }
        }
    }
    &.expanded {
        .navigation-box {
            display: block;
        }
    }
}

.page-footer {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    .common-footer {
        padding-top: 10px;
        padding-bottom: 10px;
        background: #000;
    }
    a {
        color: #fff;
    }
    .logo {
        display: inline-block;
        margin: 0 auto;
        max-width: 25vw;
    }
    .navigation-box {
        display: block;
        margin-top: 10px;
        .item {
            display: inline-block;
            padding: 5px 10px;
            font-weight: bold;
            font-size: 13px;
            .label {
                white-space: nowrap;
            }
            &:hover {
                .label {
                    color: @r_color;
                }
            }
        }

    }
}

.page-content {
    padding-top: 50px;
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 1) 0, rgba(250, 250, 250, 0.5) 10px, rgba(255, 255, 255, 0.1) 15px, transparent 16px);
    .hp-page &,
    .subpage-page & {
        padding-top: 0;
    }
    .with-bc & {
        padding-top: 35px;
    }
}

.about {
    margin-top: 40px;
    margin-bottom: 50px;
    text-align: center;
    .header {
        position: relative;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        letter-spacing: 3px;
        &:before {
            position: absolute;
            content: " ";
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background: #ABACAE;
            z-index: -1;
        }
        .title {
            display: inline-block;
            padding: 0 25px;
            margin: 0 auto;
            background: #fff;
            text-transform: uppercase;
            font-size: 25px;
        }
    }
    .content {
        .content-padding;
        text-align: left;
        font-size: 15px;
        font-weight: @fw_default;
        line-height: 1.8em;
        text-align: justify;
    }
    .contact-info,
    .photos {
        display: none;
    }
    .see-more {
        &:extend(.see-more-btn all);
    }
}

.magazines {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #B30519;
    color: #fff;
    text-align: center;
    .container {
        position: relative;
    }
    .header {
        margin: 0;
        text-transform: uppercase;
        font-family: @ff_default;
        font-size: 25px;
        font-weight: 300;
        color: #fff;
    }
    .content {
        display: none;
        max-width: 625px;
        font-weight: @fw_default;
    }
    .see-more {
        &:extend(.see-more-btn all);
    }
    a {
        &:hover {
            .see-more {
                &:extend(.see-more-btn:hover);
            }
        }
    }
}
.websites {
    &:extend(.magazines all);
    background: #66cbcb;
}

.special-projects {
    &:extend(.magazines all);
    background: #5a99cd;
}

.studio-foto {
    &:extend(.magazines all);
    background: #73566c;
}

.news {
    margin-top: 40px;
    padding-bottom: 30px;
    text-align: center;
    .header {
        &:extend(.about .header all);
        line-height: 100%;
    }
    .content {
        padding: 0 @cpadding;
        .item {
            border: 1px solid #b4b5b7;
            padding: @cpadding;
            text-align: left;
            &:hover {
                img {
                    opacity: 0.5;
                }
            }
        }
        .thumb {
            text-align: center;
            img {
                transition: opacity 0.5s;
            }
        }
        h3 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.1em;
            &:after {
                .mx-header-underscore;
                margin-top: 6px;
                width: 25vw;
            }
        }
        .description {
            margin-top: 8px;
            font-size: 14px;
        }
    }
    .see-more {
        &:extend(.see-more-btn all);
    }
}

.video {
    position: relative;
    background: #E9EAEC;
    .header {
        padding: 5vw 25vw;
        margin-bottom: 0;
        background: #B20519;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        .title {
            display: block;
            font-weight: bold;
            font-size: 28px;
            line-height: 1.2em;
            &:after {
                .mx-header-underscore;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
                width: 25vw;
                border-color: #fff;
            }
        }
        .see-more {
            display: none;
            font-family: Lato;
            &:after {
                display: none;
            }
        }
    }
    .content {
        padding: 5vw @cpadding;
        background: #E9EAEC;
        text-align: center;
        .item {
            margin: 0 auto 1.5em auto;
            max-width: 500px;
            text-align: left;
            &:hover {
                h3 {
                    color: #fff;
                    background: #000;
                    &:before {
                        position: absolute;
                        left: 0;
                        top: -4px;
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 4px;
                        background: @r_color;
                    }
                }
            }
        }
        .thumb {
            position: relative;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -38px 0 0 -38px;
                width: 76px;
                height: 76px;
                background: url(i/video_ico.png) no-repeat;
            }
        }
        h3 {
            position: relative;
            margin: 0;
            padding: 10px 5px;
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.2em;
            transition: background-color 0.5s;
        }
    }
    .see-more {
        &:extend(.see-more-btn all);
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        &:after {
            display: none;
        }
    }
}
.video-widget {
    padding: @cpadding;
    margin-top: 20px;
    background: #E9EAEC;
    text-align: center;
    .header {
        margin-top: 0;
        .title {
            text-transform: uppercase;
            font-size: 25px;
            &:after {
                .mx-header-underscore;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
                width: 25%;
                border-color: inherit;
            }
        }
        .see-more {
            &:extend(.video .header .see-more all);
        }
    }
    .list {
        .item {
            margin: 0 auto 1.5em auto;
            max-width: 500px;
            text-align: left;
            &:hover {
                h3 {
                    color: #fff;
                    background: #000;
                    &:before {
                        position: absolute;
                        left: 0;
                        top: -4px;
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 4px;
                        background: @r_color;
                    }
                }
            }
        }
        .thumb {
            position: relative;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -38px 0 0 -38px;
                width: 76px;
                height: 76px;
                background: url(i/video_ico.png) no-repeat;
            }
        }
        h3 {
            position: relative;
            margin: 0;
            padding: 5px;
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.2em;
            transition: background-color 0.5s;
        }
    }
    .see-more {
        &:extend(.video .see-more all);
    }
}
.js .gmap {
    height: 320px;
}

.slider {

    .item {
        @sliderTopPadding: 15px;
        display: none;
        text-align: center;
        &:first-child {
            display: block;
        }
        .thumb {
            display: block;
            width: 100%;
            overflow: hidden;
            background-repeat: no-repeat;
        }
        .image {
            display: block;
            width: 100%;
        }
        .content {
            position: relative;
            padding: @sliderTopPadding @cpadding;
            color: #fff;
            background: @r_color;
        }
        h3 {
            margin: 0 0 20px 0;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: 25px;
            color: #fff;
            &:after {
                &:extend(.mx-header-underscore);
                margin-left: auto;
                margin-right: auto;
                width: 20%;
                border-color: #fff;
            }
        }
        p {
            padding: 0 30px 10px 30px;
            font-weight: 400;
        }
        .prev,
        .next {
            display: none;
            position: absolute;
            top: @sliderTopPadding;
            font-size: 40px;
            cursor: pointer;
        }
        .prev {
            left: @cpadding;
        }
        .next {
            right: @cpadding;
        }
        &:nth-child(2) {
            .content {
                background: rgba(102, 203, 203, 0.85);
            }
        }
    }
}
.js .slider .item {
    display: block;
    .prev,
    .next {
        display: inline-block;
    }
}

.magazines-list {
    text-align: center;
    .header {
        &:extend(.about .header all);
        margin-top: 0;
    }
    .description {
        margin: 0 auto;
        width: 80%;
        font-weight: @fw_default;
        font-size: 19px;
    }
    .list, .item {
        list-style: none;
    }
    .list {
        margin: 30px 0 0;
        padding: 0;
    }
    .item {
        margin: 0;
        padding: 10px;
        &:hover {
            .box {
                opacity: 1;
            }
        }
    }
    .thumb {
        position: relative;
        display: block;
        .box {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            transition: opacity 0.5s;
        }
        .wrapper {
            display: table;
            table-layout: fixed;
            height: 100%;
            width: 100%;
        }
        .details {
            display: table-cell;
            padding: 15px;
            height: 100%;
            vertical-align: middle;
            p {
                margin-top: 10px;
                font-weight: @fw_default;
                font-size: 14px;
            }
        }
        h2 {
            margin-top: 0;
            font-size: 26px;
            color: #fff;
            &:after {
                &:extend(.mx-header-underscore);
                margin-left: auto;
                margin-right: auto;
                width: 60%;
                border-color: #fff;
            }
        }
        .see-more {
            &:extend(.see-more-btn all);
            padding-left: 0;
            padding-right: 0;
            width: 65%;
            font-size: 14px;
        }
    }
}

.websites-list {
    text-align: center;
    .header {
        &:extend(.about .header all);
        margin-top: 0;
    }
    .description {
        &:extend(.magazines-list .description);
        padding-bottom: 190px;
    }
    .counter {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 20px;
        height: 170px;
        background: url(i/tlo_odslony.jpg) no-repeat 50%;
        background-size: cover;
        text-align: center;
        box-sizing: border-box;
        .container {
            position: relative;
            padding-top: 20px;
            padding-bottom: 40px;
            height: 110px;
        }
        .ru, .pv {
            display: block;
            padding: 10px 0;
            .number {
                display: block;
                font-size: 32px;
                font-weight: 300;
                line-height: 22px;
                color: #767777;
            }
            strong {
                display: block;
                margin-top: 7px;
                font-size: 11px;
                font-weight: bold;
                line-height: 11px;
                text-transform: uppercase;
                color: #a4a5a5;
            }
        }
        .source {
            position: absolute;
            right: @cpadding;
            bottom: 10px;
            font-size: 12px;
        }
    }
    .list, .item {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .list {
        margin-bottom: 15px;
    }
    .item {
        position: relative;
        border-top: 1px solid #C3C4C6;
        margin-top: 15px;
        padding: 10px;
        &:hover {
            .back {
                opacity: 1;
            }
        }
    }
    .front {
        h2 {
            display: none;
            margin-top: 0;
            font-size: 26px;
            img {
                margin-left: 0;
//                max-height: 40px;
            }
        }
        p {
            padding-bottom: 10px;
            font-weight: @fw_default;
            font-size: 14px;
        }
        img {
            margin: 0 auto;
        }
        .social {
            &:before {
                &:extend(.mx-header-underscore);
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                width: 35%;
            }
        }
        .social-links {
            .fa {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }
    }
    .back {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        transition: opacity 0.5s;
        .wrapper {
            display: table;
            height: 100%;
            width: 100%;
        }
        .details {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
        h2 {
            margin: 0 auto;
            font-size: 26px;
            color: #fff;
            img {
                margin: 0 auto;
                max-height: 110px;
            }
        }
        .see-more {
            &:extend(.see-more-btn all);
        }
    }
//    .autostuffpl .back {
//        background: rgba(48, 104, 153, 0.93);
//    }
//    .boskapl .back {
//        background: rgba(74, 125, 118, 0.93);
//    }
//    .ckmpl .back {
//        background: rgba(202, 48, 50, 0.93);
//    }
//    .cosmopolitanpl .back {
//        background: rgba(232, 64, 125, 0.93);
//    }
//    .harpersbazaarpl .back {
//        background: rgba(193, 155, 118, 0.93);
//    }
//    .jamiemagazinepl .back {
//        background: rgba(138, 209, 195, 0.93);
//    }
//    .joypl .back {
//        background: rgba(232, 84, 146, 0.93);
//    }
//    .kozaczekpl .back {
//        background: rgba(202, 42, 90, 0.93);
//    }
//    .harpersbazaarpl .back {
//        background: rgba(193, 155, 118, 0.93);
//    }
//    .milionkobietpl .back {
//        background: rgba(208, 80, 154, 0.93);
//    }
//    .papilotpl .back {
//        background: rgba(235, 120, 161, 0.93);
//    }
//    .supermamypl .back {
//        background: rgba(135, 209, 210, 0.93);
//    }
//    .voyagepl .back {
//        background: rgba(240, 51, 117, 0.93);
//    }
//    .zeberkapl .back {
//        background: rgba(194, 109, 168, 0.93);
//    }
//    .hotmodapl .back,
//    .esquirepl .back {
//        background: rgba(147, 148, 151, 0.93);
//    }
//    .supermamypl .back {
//        background: rgba(150, 213, 212, 0.93);
//    }
//    .boskapl .back {
//        background: rgba(228, 67, 134, 0.93);
//    }
//    .papilotpl .back {
//        background: rgba(231, 125, 153, 0.93);
//    }
}

.social-links {
    font-size: 16px;
    .fa {
        display: inline-block;
        border-style: solid;
        border-width: 1px;
        border-color: inherit;
        border-radius: 50%;
        margin: 0 3px;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
    }
    .fa-facebook:hover {
        color: #456CB2;
        border-color: #456CB2;
    }
    .fa-twitter:hover {
        color: #3BA0FF;
        border-color: #3BA0FF;
    }
    .fa-instagram:hover {
        color: #927651;
        border-color: #927651;
    }
}

.magazine-single {
    text-align: center;
    .header {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        &:after {
            &:extend(.mx-header-underscore);
            margin-left: auto;
            margin-right: auto;
            width: 50%;
        }
    }
    img {
        display: inline-block;
    }
    .social {
        margin-top: 10px;
    }
    .website-url {
        display: block;
        margin: 20px auto;
        font-size: 18px;
        font-weight: 700;
        &:hover {
            opacity: 0.7;
        }
    }
    .description {
        font-size: 14px;
        line-height: 1.8em;
        h1, h2, h3, h4, h5 {
            margin: 10px 0 15px 0;
            text-transform: uppercase;
            &:after {
                &:extend(.mx-header-underscore);
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
                width: 40%;
            }
        }
        p {
            padding: 5px 0;
        }
        .editors {
            margin-top: 15px;
            border-top: 1px solid #D8D8D8;
            padding: 15px 0;
            img {
                margin-right: 20px;
                vertical-align: middle;
            }
            p {
                display: inline-block;
            }
        }
    }
    .btn {
        display: block;
        margin: 0 -@cpadding 15px -@cpadding;
        padding: 20px;
        font-family: Lato,sans-serif;
        font-weight: @fw_default;
        font-size: 18px;
        background: @r_color;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        &.linked {
            background: #73c5c3;
        }
        &.special-projects {
            background: #81afe0;
        }
        &:hover {
            opacity: 0.8;
        }
    }
    .fn {
        margin-top: 15px;
    }
    .files {
        &:hover {
            .list {
                display: block;
            }
        }
        .list {
            display: none;
            margin: -@cpadding -@cpadding 0 -@cpadding;
            border-top: 3px solid #000;
            border-bottom: 3px solid #000;
            padding: 1px 0;
            .file {
                display: block;
                padding: 10px 10px;
                border-top: #9d9e9e 1px solid;
                text-transform: uppercase;
                font-size: 14px;
                color: #222;
                border-top: #9d9e9e 1px solid;
                &:hover {
                    color: @r_color;
                }
                &:first-child {
                    border-top: 0;
                }
            }
        }
    }
}
.other-magazines {
    margin-top: 40px;
    border-top: 1px solid #abacae;
    padding-top: 35px;
    .header {
        &:extend(.about .header all);
        display: none;
    }
    .list {
        margin: 0;
        padding: 0;
        font-size: 0;
        text-align: center;
        .item {
            display: inline-block;
            margin: 0 auto;
            padding: 5px;
            width: 50%;
            font-size: @fs_default;
            box-sizing: border-box;
        }
        .thumb {
            display: block;
            overflow: hidden;
            transition: opacity 0.5s;
            &:hover,
            &.active {
                opacity: 0.3;
            }
        }
    }
}
.other-websites {
    &:extend(.other-magazines all);
    .list {
        .item {
            margin: 10px auto;
        }
    }
}
.important-events {
    margin-top: 50px;
    text-align: center;
    .header {
        &:extend(.about .header all);
        margin-top: 0;
    }
    .timeline {
        margin-top: 180px;
        margin-bottom: 50px;
        height: 200px;
        border-top: 1px dotted #e22404;
        position: relative;
        padding-left: 30px;
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -13px;
            left: 0;
            background: #fff url(i/timeline-sprite.png) 0px 0px no-repeat;
            width: 15px;
            height: 25px;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: -13px;
            right: 0px;
            background: #fff url(i/timeline-sprite.png) 0px -25px no-repeat;
            width: 20px;
            height: 25px;
        }
        li {
            position: relative;
            float: left;
            min-width: 50px;
            width: 4.5%;
            .date {
                box-sizing: border-box;
                width: 40px;
                height: 155px;
                padding: 0px;
                margin-top: 20px;
                margin-left: 0px;
                background: #f1f1f2;
                color: #908e8f;
                text-transform: uppercase;
                text-align: right;
                cursor: pointer;
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -35px;
                    width: 40px;
                    height: 55px;
                    background: url(i/timeline-sprite.png) -20px -6px no-repeat;
                    text-align: left;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -80px;
                    width: 40px;
                    height: 80px;
                    background: linear-gradient(180deg, rgba(248,248,249,1) 0%,rgba(255,255,255,1) 100%);
                }
                span {
                    transform: rotate(-90deg);
                    display: block;
                    height: 20px;
                    width: 150px;
                    text-align: right;
                    position: relative;
                    top: 72px;
                    margin-left: -55px;
                }
            }
            .event {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                width: 400px;
                height: 140px;
                top: -170px;
                left: -30px;
                text-align: left;
                transition: opacity .3s, visibility .2s;
                img {
                }
            }
            &:hover,
            &.hover {
                .date {
                    background: rgb(206,208,210);
                    &::before {
                     background-position: -60px -6px;
                    }
                }
                .event {
                    visibility: visible;
                    opacity: 1;
                }
            }
            &.playboy {
                width: 7%;
            }
            &.cosmopolitan {
                width: 4%;
            }
            &.ckm {
                width: 5.5%;
            }
            &.shape {
                width: 7%;
            }
            &.joy {
                width: 5.5%;
            }
            &.hot {
                width: 5.5%;
            }
            &:nth-child(n+3) {
                .event {
                    left: -90px;
                }
            }
            &:nth-child(n+6) {
                .event {
                    left: -150px;
                }
            }
            &:nth-child(n+9) {
                .event {
                    left: -210px;
                }
            }
            &:nth-child(n+12) {
                .event {
                    left: -270px;
                }
            }
            &:nth-child(n+15) {
                .event {
                    left: -330px;
                }
            }
        }
    }
}
.subpage {
    position: relative;
    padding-top: 50px;
    padding-bottom: 30px;
    &.full {
        padding-top: 0;
    }
    &:not(.full) {
        .title {
            &:extend(.article-single .header all);
        }
    }
    h1, h2, h3, h4, h5 {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 30px;
        &:after {
            &:extend(.mx-header-underscore);
            width: 30%;
            min-width: 100px;
        }
    }
    .header {
        .full& {
            height: 250px;
            line-height: 250px;
            background: url('i/tlo_reklama.jpg') no-repeat 50%;
            background-size: cover;
            text-align: center;
            color: #fff;
            .title {
                display: inline-block;
                line-height: normal;
                font-size: 40px;
                &:after {
                    &:extend(.mx-header-underscore);
                    margin-left: auto;
                    margin-right: auto;
                    border-color: #fff;
                }
            }
        }
        .kontakt& {
            background-image: url('i/tlo_kontakt.jpg');
        }
        .projekty-specjalne& {
            background-image: url('i/tlo_projekty.jpg');
        }
        .kariera& {
            background-image: url('i/tlo_kariera.jpg');
        }
        &:after {
            display: none;
        }
    }
    .content {
        margin-top: 40px;
        line-height: 1.5em;
        font-weight: 400;
        font-size: 17px;
        h1, h2, h3, h4, h5 {
            margin: 20px 0;
            &:after {
                margin-top: 20px;
            }
        }
        p {
            padding: 5px 0;
        }
        table {
            margin: 0 auto;
            width: 100%;
            border-spacing: 0;
        }
        th, td {
            border-bottom: 1px solid #D4D4D4;
            padding: 10px;
        }
        tr:nth-child(even) {
            th, td {
                background: #F6F6F6;
            }
        }
    }
    .contact-info {
        &:extend(.about .contact-info all);
    }
    .logo {
        display: block;
        img {
            max-height: 60px;
        }
    }
    &.reklama {
        .list {
            margin-top: 15px;
            h2 {
                padding: 15px 0;
                background: #B30519;
                color: #fff;
                text-align: center;
                &:after {
                    display: none;
                }
            }
            .add_regulations,
            .add_pricing{
                text-align: center;
                background: #C0C0C0 ;
                font-size: 20px;
                font-weight: 700;
                font-family: Lato,sans-serif;
                transition: all 0.7s;
            }
            .add_regulations:hover,
            .add_pricing:hover{
                background: #B30519;
            }
            .item {
                padding: 20px 0;
                margin: 5px 0;
                background: #F6F6F6;
                text-align: center;
                transition: background 0.5s;
                a {
                    display: block;
                }
                p {
                    display: none;
                }
                &:hover {
                    background: #A8A9AD;
                    color: #fff;
                }
            }
        }
        .websites-adv {
            h2 {
                background: #66cbcb;
            }
            .add_regulations:hover,
            .add_pricing:hover{
                background: #66cbcb;
            }
        }



    }
    &.kontakt {
        .row{
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #D8D8D8;
            text-align: center;
            &.management {
                >.row {
                    border-bottom: none;
                    padding-bottom: 0px;
                    .col {
                        p {
                            line-height: 1.2em;
                        }
                        .person {
                            font-family: @ff_headers;
                            font-size: 19px;
                            color: #5a5b5e;
                        }
                        .position {
                            margin-top: 5px;
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: bold;
                            line-height: 16px;
                            letter-spacing: 1px;
                            color: #adafb2;
                        }
                    }
                }
            }
            h4 {
                &:after {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &:last-child {
                margin-bottom: 0;
                border: none;
                padding-bottom: 0;
            }
        }
        .col {
            margin-bottom: 15px;
        }
    }
    &.projekty-specjalne {
        .content {
           text-align: justify;
        }
    }
}
.addlayer {
    display: none;
    min-width: 80% !important;
    .wrapper {
        background: #fff;
    }
    .b-close {
        position: absolute;
        display: inline-block;
        top: -15px;
        right: -15px;
        padding: 5px;
        width: 25px;
        height: 25px;
        font-size: 20px;
        line-height: 25px;
        color: #fff;
        background: #000;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
    }
    .b-ajax-wrapper {
        padding: 10px;
    }
}


.ext-widget {
    margin-top: 20px;
    iframe {
        border: none;
        width: 100%;
    }
}

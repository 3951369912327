/*
    Author     : rkiedrowski
*/


.application-form-box {
    max-width: 800px;
    margin: 50px auto 20px;
    .recrutation-info {
        text-align: center;
        margin: 20px auto;
        font-size: 1.1em;
        p {
            margin-bottom: 5px
        }
    }
}
#application-form {
    margin: 40px auto 20px;
    font-size: 16px;

    label {
        text-align: center;
        text-transform: none;
        font-size: 1.1em;
    }
    .hint {
        font-size: 14px;
        color: #7c7c7c;
    }
    .form-errors {
        margin: 5px auto;
    }
    .item-text {
        max-width: 400px;
        margin: auto;
        margin-bottom: 20px;
        input {
            width: 400px;
            max-width: 100%;
            border: 0;
            border-bottom: 1px dotted #888;
            font-size: 18px;
        }
    }

    .item-file {
        max-width: 400px;
        margin: 20px auto;

        .label {
            padding-bottom: 1px;
            margin-bottom: 1px;
        }
        .button {
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            display: inline-block;
            width: 100%;
            height: 34px;
            color: #4e504f;
            background: #e6e6e6;
            border: 1px solid #bbb;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
            line-height: 34px;
            font-size: 1.1em;
        }
        .button:hover {
            background: #bbb;
        }
        .button:active {
            background: #aaa;
        }
        .button input{
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            opacity: 0;
            -ms-filter: 'alpha(opacity=0)';
            font-size: 200px;
            direction: ltr;
            cursor: pointer;
        }
        .file-info {
            width: 100%;
            height: 40px;
            overflow: hidden;
            margin-top: 10px;
            color: #4e504f;
            background: #f4f4f4;
            text-align: center;
            text-transform: none;
            line-height: 40px;
            font-size: 16px;
        }
    }

    .item-checkbox {
        &.required {
            color: #c51f22;
        }
        label {
            display: inline-block;
            padding-left: 20px;
            width: auto;
            font-size: 1.0em;
            text-align: left;
        }
        label a{
            text-decoration: underline;
        }
        input{
            position: absolute;
            margin-top: 8px
        }
    }

    .item-submit {
        margin-top: 20px;

        input {
            display: block;
            width: 300px;
            height: 50px;
            margin: 20px auto;
            color: #fff;
            background: #c51f22;
            border: 1px solid #c50a0d;
            text-transform: uppercase;
            font-size: 28px;
            cursor: pointer;
        }
        input:hover{
            background: #c50a0d;
        }
        input:active{
            background: #c50004;
        }
    }

    #data-processing-info {
        margin: 35px auto 5px;
        font-size: 0.9em;
        text-align: justify;
    }
}

.job-offers {
    h2 {
        text-transform: uppercase;
        font-size: 1.2em;
    }
    .job-offer-list {
        margin-top: 10px;
        li {
            text-align: center;
            a {
                box-sizing: border-box;
                display: block;
                height: 150px;
                margin: 20px 6px;
                padding: 25px;
                border: 2px solid #e4e4e4;
                text-transform: uppercase;
                .title {
                    font-family: @ff_default;
                    font-size: 1.1em;
                    color: #323232;
                    &::after {
                        content: " ";
                        display: block;
                        border-top: 1px solid #999999;
                        border-bottom: 1px solid #666666;
                        height: 2px;
                        width: 80px;
                        margin: 15px auto;

                    }
                }
                .department {
                    color: #6b6c70;
                }
                &:hover {
                    background: #bd292c;
                    .title {
                        color: #fff;
                        &::after {
                            border-top-color: #fff;
                            border-bottom-color: #fff;

                        }
                    }
                    .department {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.job-offer {
    margin: auto;
    .title {
        font-family: @ff_default;
        font-size: 1.2em;
        font-weight: 600;
        color: #323232;
        text-transform: uppercase;
        text-align: center;
        &::after {
            content: " ";
            display: block;
            border-top: 1px solid #999999;
            border-bottom: 1px solid #666666;
            height: 2px;
            width: 80px;
            margin: 20px auto;

        }
    }
    .department {
        font-family: @ff_default;
        font-weight: 600;
        color: #323232;
        text-transform: uppercase;
        text-align: center;
    }
    .content {
        max-width: 680px;
        margin: auto;
        color: #7b7b7b;
        font-size: 15px;
        line-height: 1.5em;
        .description,
        .requirements {
            margin-top: 50px;
            h3 {
                font-family: @ff_default;
                font-weight: 600;
                color: #323232;
                text-transform: uppercase;
            }
            li {
                list-style: disc;
                margin-left: 20px;
            }
        }

    }

}
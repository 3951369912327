@media screen and (max-width: 480px) {
    .ext-widget {
        .widget-voyage {
            height: 57vw;
        }
    }
    .studio-foto-menu {
        .navigation {
            a {
                display: block;
                border-left: none;
                padding-left: 0;
                margin-left: 0;
            }
        }
    }
    .studio-foto-container {
        h1 {
            font-size: 30pt;
            font-weight: 300;
        }
    }

}

@media screen and (max-width: 400px) {
    .studio-foto-container {
        h1 {
            font-size: 28pt;
            font-weight: 300;
        }
        p {
            font-size: 17pt;
        }
    }

}
@media screen and (max-width: 360px) {
    .studio-foto-container {
        .content {
            line-height: 26pt;
        }
        h1 {
            font-size: 24pt;
            font-weight: 300;
        }
        p {
            font-size: 15pt;
        }
        &.strefa-flash {
        h1 {
            top: 64px;
            font-size: 62pt;
        }
        h2 {
            top: 160px;
            font-size: 13.5pt;
        }
    }
    }

}

@media screen and (min-width: 480px) {
    .magazines-list,
    .websites-list {
        .list {
            font-size: 0;
        }
        .item {
            display: inline-block;
            width: 33.33%;
            box-sizing: border-box;
            font-size: @fs_default;
            vertical-align: top;
        }
        .thumb {
            display: block;
            overflow: hidden;
        }
    }
    .websites-list {
        .list {
            margin: 50px auto 0 auto;
            text-align: left;
        }
        .item {
            position: relative;
            border: none;
            margin: 1%;
            height: 56vw;
-webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.05);
			width:31.33%
        }
        .thumb {
            height: 25vw;
        }
        .front {
            .details {
                margin-top: 2%;
            }
        }
        .social {
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
        }
    }
    .other-magazines {
        .list {
            .item {
                width: 33.33%;
            }
        }
    }
    .video-list {
        .list {
            font-size: 0;
            .item {
                display: inline-block;
                padding: 5px;
                margin-bottom: 0.5em;
                width: 50%;
                height: 40vw;
                box-sizing: border-box;
                overflow: hidden;
                font-size: @fs_default;
            }
        }
    }
    .subpage {
        &.reklama {
            .list {
                font-size: 0;

                .no-border{
                	position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -20px;
                        width: 0;
                        height: 0;
                        border-left: 0px solid transparent;
                        border-right: 0px solid transparent;
                        border-top: 0px solid #B30519;
                    }
                }
                .add_pricing {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -20px;
                        width: 0;
                        height: 0;
                        border-left: 0px solid transparent;
                        border-right: 0px solid transparent;
                        border-top: 0px solid #B30519;
                    }
                }
                h2 {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -20px;
                        width: 0;
                        height: 0;
                        border-left: 20px solid transparent;
                        border-right: 20px solid transparent;
                        border-top: 20px solid #B30519;
                    }
                }
                ul {
                    margin-top: -10px;
                }
                .item {
                    display: inline-block;
                    width: 50%;
                    font-size: @fs_default;
                    box-sizing: border-box;
                    &:nth-child(2n+1) {
                        border-right: 5px solid #fff;
                    }
                    &:nth-child(2n+2) {
                        border-left: 5px solid #fff;
                    }
                }
            }
            .websites-adv {
                h2 {
                    &:before {
                        border-top-color: #66cbcb;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .slider {
        .item {
            h3 {
                font-size: 30px;
            }
            .prev,
            .next {
                font-size: 50px;
            }
        }
    }
    .news {
        .content {
            display: table;
            table-layout: fixed;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            font-size: 0;
            .item {
                display: table-cell;
                width: 33.33%;
                box-sizing: border-box;
                font-size: @fs_default;
                vertical-align: top;
            }
            h3 {
                &:after {
                    width: 10vw;
                }
            }
        }
    }
    .js .gmap {
        height: 480px;
    }
    .websites-list {
        .item {
            height: 50vw;
        }
        .thumb {
            height: 27vw;
        }
    }
    .video-widget {
        .list {
            display: table;
            table-layout: fixed;
            width: 100%;
            .item {
                display: table-cell;
                padding: 0 5px;
                width: 33.33%;
            }
            h3 {
                font-size: 16px;
            }
        }
    }
    .article-list {
        .list {
            padding-top: 20px;
            .item {
                border: none;
            }
            h2 {
                margin-top: 0;
            }
            .details {
                float: left;
                width: 60%;
            }
        }
        .thumb {
            float: left;
            padding-right: 20px;
            width: 40%;
            box-sizing: border-box;
        }
    }
    .video-list {
        .list {
            .item {
               height: 38vw;
            }
        }
    }
    .websites-list {
        .counter {
            .container {
                padding-top: 40px;
                height: 90px;
            }
            .ru, .pv {
                display: inline-block;
                padding: 0 30px;
                .number {
                    font-size: 50px;
                    line-height: 40px;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .breadcrubs {
        display: block;
    }
    .common-header {
        .mobile-btn {
            display: none;
        }
        .navigation-box {
            display: block;
            position: static;
            float: right;
            width: auto;
        }
        .navigation {
            float: left;
            .item {
                display: inline-block;
                padding: 17px 10px;
                font-size: 12px;
                /*&:hover {
                    .submenu {
                        opacity: 1;
                        height: auto;
                        z-index: 100;
                    }
                }*/
            }
        }
        .search-form {
            float: right;
            margin-top: 15px;
        }
    }
    .slider {
        .item {
            h3 {
                font-size: 35px;
            }

        }
    }
    .about {
        .header {
            .title {
                font-size: 35px;
            }
        }
        .content {
            columns: 2;
            column-gap: 60px;
        }
    }
    .magazines {
        .header {
            font-size: 30px;
        }
    }
    .video {
        .header {
            .title {
                &:after {
                    width: 10vw;
                }
            }
        }
    }

    .magazines-list {
        .item {
            width: 33.33%;
        }
    }
    .websites-list {
        .item {
            height: 42vw;
        }
    }
    .magazine-single {
        .header {
            margin-top: 0;
            margin-left: 0;
            margin-bottom: 20px;
            width: 50%;
            &:after {
                margin-left: 0;
                margin-top: 20px;
                width: 40%;
            }
        }
        .description {
            font-weight: @fw_default;
            h1, h2, h3, h4, h5 {
                &:after {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }
        .column {
            float: left;
            width: 55%;
            box-sizing: border-box;
            text-align: left;
            &:first-of-type {
                padding-right: 20px;
                width: 45%;
                text-align: center;
            }
        }
        .btns {
            clear: both;
            padding-top: 20px;
        }
        .btn {
            margin-left: 0;
            margin-right: 0;
        }
        .files {
            .list {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .website-url {
            padding: 20px 0;
            border-top: 1px solid #D8D8D8;
            border-bottom: 1px solid #D8D8D8;
        }
    }
    .other-magazines {
        .item {
            width: 25%;
        }
    }
    .other-websites {
        .item {
            padding: 10px;
        }
    }
    .article-single {
        margin: 0 auto;
        width: 700px;
        .header {
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 1.2em;
            &:after {
                margin-top: 25px;
            }
        }
    }
    .video-list {
        .list {
            .item {
                width: 33.33%;
                height: 27vw;
            }
        }
    }
    .video-single {
        margin: 0 auto;
        width: 700px;
        .header {
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 1.2em;
            &:after {
                margin-top: 25px;
            }
        }
    }
    .projekty-specjalne {
        .content {
            columns: 2;
            column-gap: 60px;
        }
    }
    .kariera {
        .content {
            columns: 2;
            column-gap: 60px;
        }
    }
    .job-offers {
        .job-offer-list {
            li {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                margin: 0 -0.1em;
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .important-events {
        margin-bottom: 50px;
        .timeline {
            margin-top: auto;
            margin-bottom: auto;
            height: auto;
            border-top: none;
            border-left: 1px dotted #e22404;
            padding-top: 10px;
            padding-left: 0px;
            margin-left: 12px;
            &::before {
                top: -13px;
                left: -5px;
            }
            &::after {
                top: auto;
                right: auto;
                left: -10px;
                bottom: -20px;
                transform: rotate(90deg);
            }
            li {
                position: relative;
                float: none;
                width: 100% !important;
                .date {
                    width: auto;
                    height: 30px;
                    padding: 0px;
                    margin-top: 10px;
                    margin-left: 50px;
                    background: none !important;
                    &::before {
                        transform: rotate(90deg);
                        top: -16px;
                        left: -13px;
                    }
                    &::after {
                        display: none;
                    }
                    span {
                        position: static;
                        transform: rotate(0deg);
                        height: auto;
                        width: auto;
                        margin: auto;
                        text-align: left;
                    }
                }
                .event {
                    visibility: visible;
                    opacity: 1;
                    position: static;
                    width: auto;
                    max-width: 100%;
                    transition: none;
                }

            }
        }
    }
}

@media screen and (min-width: 980px) {
    .page-header.fixed-active {
        position: fixed;
        top: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #E3E3E3;
        width: 100%;
        background: #fff;
        z-index: 1000;
        .common-header {
            margin-bottom: 0;
            height: 40px;
        }
        .logo {
            width: 201px;
            max-width: none;
            height: 40px;
            background: url(i/logo-nmw.png) no-repeat center;
			background-size: contain;
            img {
                display: none;
            }
        }
        .navigation {
            .item {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }
    .common-header {
        .navigation {
            .item {
                padding: 22px 15px;
                font-size: 13px;
            }
        }
        .search-form {
            margin-top: 20px;
        }
    }
    .page-footer {
        margin-top: 80px;
        .common-footer {
            padding: 0;
        }
        .wrapper {
            display: table;
            table-layout: fixed;
            width: 100%;
        }
        .logo {
            display: table-cell;
            width: 15%;
        }
        .navigation-box {
            display: table-cell;
            width: 85%;
            vertical-align: middle;
            text-align: right;
        }

    }
    .slider {
        .item {
            h3 {
                font-size: 40px;
                &:after {
                    width: 40%;
                }
            }
            p {
                font-size: 19px
            }
            .prev,
            .next {
                font-size: 60px;
            }
        }
    }
    .about {
        .header {
            .title {
                font-size: 40px;
            }
        }
        .contact-info {
            display: block;
            float: left;
            margin: 0 40px 0 @cpadding;
            text-align: left;
            font-size: 14px;
            h3 {
                display: inline-block;
                margin-top: 0;
                margin-bottom: 20px;
                text-transform: uppercase;
                font-size: 23px;
                &:after {
                    .mx-header-underscore;
                    margin-top: 20px;
                }
            }
        }
        .photos {
            display: block;
            clear: both;
            margin-top: 30px;
            transition: 0.5s margin;
            .item {
                display: inline-block;
                padding: 0 30px;
                margin: 0;
                width: 20%;
                box-sizing: border-box;
                text-align: center;
                transition: 0.5s padding;
                font-size: 13px;
                .thumb {
                    margin: 0 auto 10px auto;
                    width: 136px;
                    height: 136px;
                    transition: 0.5s width, 0.5s height;
                    img {
                        border-radius: 50%;
                    }
                }
                em {
                    font-style: normal;
                    display: block;
                    color: #9E9E9E;
                }
            }
        }
        .content {
            font-size: 16px;
        }
        .see-more {
            float: right;
            margin-right: @cpadding;
        }
    }
    .magazines {
        position: relative;
        text-align: left;
        .header {
            font-size: 40px;
        }
        .content {
            display: block;
            height: 40px;
        }
        .see-more {
            position: absolute;
            right: @cpadding;
            top: 50%;
            margin-top: -20px;
        }
    }
    .news {
        padding-bottom: 0;
        .content {
            width: 100%;
            max-width: 980px;
            .item {
                width: 326px;
                h3 {
                    font-size: 25px;
                }
            }
        }
        .see-more {
            float: right;
            margin-right: calc(~'(100% - 3*326px)/2');
        }
    }
    .video {
        display: table;
        table-layout: fixed;
        margin-top: 40px;
        width: 100%;
        .header {
            display: table-cell;
            margin-top: 0;
            padding: 20px 20px 20px 0;
            margin-right: 1vw;
            width: 25%;
            text-align: right;
            vertical-align: middle;
            .title {
                display: block;
                float: right;
                padding-left: 20%;
                &:after {
                    margin-right: 0;
                    width: 30%;
                }
            }
            .see-more {
                display: inline-block;
            }
        }
        .content {
            display: table-cell;
            padding: 0 10px;
            width: 75%;
            font-size: 0;
            vertical-align: top;
            .item {
                float: left;
                padding: 20px 10px 0 10px;
                margin: 0;
                width: 33.33%;
                font-size: @fs_default;
                box-sizing: border-box;
            }
        }
        .see-more {
            display: none;
        }
    }
    .video-widget {
        .container {
            position: relative;
        }
        .header {
            position: absolute;
            top: 25%;
            padding: 0;
            width: 15%;
            text-align: center;
            font-size: 30px;
            box-sizing: border-box;
        }
        .list {
            float: right;
            box-sizing: border-box;
            width: 85%;
            .item {
                padding: 0 10px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
    .magazines-list {
        .item {
            width: 16.66%;
        }
    }
    .websites-list {
        .item {
            height: 190px;
        }
        .front {
            display: table;
            table-layout: fixed;
            height: 100%;
            width: 100%;
            h2 {
                display: block;
                margin-bottom: 10px;
            }
            .social {
                left: 5px;
                &:before {
                    margin-left: 0;
                }
            }
            .thumb,
            .details {
                display: table-cell;
                margin: 0;
                width: 50%;
                height: auto;
                vertical-align: middle;
            }
            .thumb {
                vertical-align: middle;
            }
            .details {
                position: relative;
//                padding-bottom: 45px;
//                padding-left: 5px;
                text-align: left;
            }
        }
        .counter {
            .ru, .pv {
                .number {
                    font-size: 70px;
                    line-height: 60px;
                }
                strong {
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }
    }
    .magazine-single {
        position: relative;
        .column {
            width: 60%;
            &:first-of-type {
                padding-right: 30px;
                width: 40%;
            }
        }
        .description {
            .columns {
                padding-bottom: 5px;
                column-count: 2;
                column-width: 40px;
                p {
                    break-inside: avoid;
                }
            }
        }
        .btns {
            float: right;
            clear: none;
            width: 60%;
        }
        .btn {
            margin-left: 0;
            margin-right: 0;
            padding-top: 0;
            padding-bottom: 0;
            height: 78px;
            line-height: 78px;
            box-sizing: border-box;
        }
        .fn {
            display: table;
            table-layout: fixed;
            margin-bottom: 15px;
            width: 100%;
            .item {
                display: table-cell;
                position: relative;

                border-right: 8px solid #fff;
                vertical-align: middle;
                .btn {
                    display: table-cell;
                    line-height: normal;
                    vertical-align: middle;
                }
                &:first-child {
                    border-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }
                &.files {
                    display: table;
                    box-sizing: border-box;
                    width: 100%;
                }
            }
            .btn {
                padding: 0 12px;
                font-size: 16px;
                line-height: normal;
            }
        }
        .social-links {
            padding-top: 10px;
            .fa {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 25px;
            }
        }
        .files {
            .list {
                position: absolute;
                top: 100%;
                left: 0;
                margin: 0;
                width: 100%;
                background: #f2f2f2;
                z-index: 10;
                text-align: left;
            }
            .btn {
                position: relative;
                padding-left: 35px !important;
                text-align: left;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    margin-top: -8px;
                    content: "\f019";
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    font-smoothing: antialiased;
                    .website-single& {
                        position: static;
                        margin: 0 10px 0 0;
                    }
                }
                .website-single& {
                    padding-left: 15px !important;
                    text-align: center;
                }
            }
        }
    }
    .other-magazines {
        .list {
            display: table;
            table-layout: fixed;
            width: 100%;
            .item {
                display: table-cell;
                width: auto;
                vertical-align: top;
            }
        }
    }
    .other-websites {
        .list {
            display: block;
            .item {
                display: inline-block;
                width: 20%;
            }
        }
    }
    .subpage {
        .header {
            .full& {
                height: 350px;
                line-height: 350px;
            }
        }
        .content {
            .columns {
                column-count: 2;
                column-width: 40px;
                p {
                    break-inside: avoid;
                }
            }
        }
/*        .logo {
            display: block;
            position: absolute;
            top: 60px;
            right: @cpadding_wide;
            max-width: 200px;
        }*/
        &.reklama {
            .list {
                float: left;
                margin-top: 50px;
                width: 50%;
                box-sizing: border-box;
                ul {
                    margin-top: -13px;
                }
                .item {
                    margin: 10px 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 20px;
                    font-weight: @fw_default;
                    vertical-align: middle;
                    &:nth-child(2n+1) {
                        border-right-width: 10px;
                    }
                    &:nth-child(2n+2) {
                        border-left-width: 10px;
                    }
                    p {
                        display: block;
                        text-transform: uppercase;
                        font-size: 10px;
                        font-weight: 700;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
            .magazines-adv {
                padding-right: 10px;
            }
            .websites-adv {
                padding-left: 10px;
            }
        }
        &.kontakt {
            .row {
                margin-top: 70px;
                margin-bottom: 70px;
                padding-bottom: 70px;
                font-size: 0;
                h4 {
                    font-size: 18px;
                }
                &:last-child {
                    margin-top: 0;
                    margin-bottom: 40px;
                }
                &.management {
                    margin-top: -10px;
                    >.row {
                        margin-top: 50px;
                        margin-bottom: 10px;
                        .col {
                            border-left: 2px solid #d8d8d8;
                            &:first-child {
                                border-left: none;
                            }
                        }
                    }
                }
            }
            .col {
                display: inline-block;
                min-width: 10%;
                margin: 0;
                padding: 0 20px;
                font-size: @fs_default;
                vertical-align: top;
                box-sizing: border-box;
            }
            .row-3 {
                .col {
                    width: 33%;
                }
            }
            .row-4 {
                .col {
                    width: 25%;
                }
            }
            .row-5 {
                .col {
                    width: 20%;
                }
            }
            .row-6 {
                .col {
                    width: 16.66%;
                }
            }
            .row-7 {
                .col {
                    width: 14.285%;
                }
            }
            .row-8 {
                .col {
                    width: 12.5%;
                }
            }
        }
    }
    .article-list {
        .list {
            padding-top: 30px;
            .details {
                width: 65%;
                font-size: 16px;
            }
        }
        .thumb {
            padding-right: 30px;
            width: 35%;
        }
    }
    .video-list {
        .list {
            .item {
                width: 25%;
                height: 232px;
            }
        }
    }
    .cookie-policy-box {
        width: 900px;
        margin-left: -450px;
    }
    .job-offers {
        .job-offer-list {
            li {
                width: 33.3%;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .container {
        margin-left: auto;
        margin-right: auto;
        width: 1170px;
    }
    .container-wide {
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
    }
    .slider {
        /*
        .bx-viewport {
            height: 448px !important;
        }*/
        .item {
            position: relative;
            .wrapper {
                position: absolute;
                top: 0;
                              
                width: 100vw;
                height: 100%;
            }
            .thumb {
                height: 447px;
                background-size: cover;
                background-position: 50%;
                .image {
                    .js & {
                        display: none;
                    }

                }
            }
            .content {
                display: table;
                position: absolute;
                right: @cpadding_wide;
                width: 100vw;
                height: 100%;
right:0;
                box-sizing: border-box;
                background: transparent;
                .box {
                    display: table-cell;
                    vertical-align: middle;
                }
            }
            p {
                padding: 0;
            }
            .prev,
            .next {
                position: static;
                margin-top: 20px;
                padding: 0 10px;
                font-size: 50px;
            }
            &:nth-child(2) {
                .content {
                    background: transparent;
                }
            }
        }
    }
    .about {
        min-width: none !important;
        .contact-info {
            margin-left: @cpadding_wide;
        }
        .photos {
            margin-top: 50px;
            .item {
                padding: 0 40px;
                .thumb {
                    width: 176px;
                    height: 176px;
                }
            }
        }
        .see-more {
            margin-right: @cpadding_wide;
        }
    }
    .magazines {
        .header {
            font-size: 45px;
        }
        .see-more {
            right: @cpadding_wide;
        }
    }
    .video {
        .header {
            border-right-width: 12px;
            .title {
                padding-left: 40%;
            }
        }
    }
    .video-widget {
        padding-left: 0;
        padding-right: 0;
        .header {
            padding: 0 20px;
            font-size: 35px;
        }
        .list {
            h3 {
                font-size: 20px;
            }
        }
    }
/*    .magazines-list {
        .item {
            width: 20%;
        }
    }*/
    .websites-list {
        .item {
            height: 200px;
        }
        .counter {
            .container {
                padding-top: 30px;
                padding-bottom: 40px;
                height: 100px;
            }
            .source {
                right: @cpadding_wide;
            }
            .ru, .pv {
                padding: 0 40px;
                .number {
                    font-size: 82px;
                    line-height: 72px;
                }
                strong {
                    font-size: 17px;
                    line-height: 17px;
                }
            }
        }
    }
    .social-links {
        .fa {
            margin: 0 5px;
        }
    }
    .magazine-single {
        .btn {
            font-size: 20px;
        }
        .fn {
            .btn {
                padding: 15px;
                font-size: 19px;
            }
        }
        .editors {
            width: 60%;
        }
        .website-url {
            .website-single& {
                margin-top: 30px;
            }
        }
    }
    .article-list {
        .thumb {
            padding-right: 40px;
        }
        .list {
            padding-top: 40px;
            h2 {
                font-size: 24px;
            }
        }
    }
    .article-single {
        width: 700px;
        .header {
            font-size: 43px;
        }
    }
    .video-single {
        width: 700px;
        .header {
            font-size: 26px;
        }
    }
    .subpage {
        .header {
            .full& {
                height: 450px;
                line-height: 450px;
            }
        }
    }
}
/*
    Author     : rkiedrowski
*/


.studio-foto-page {
    .page-content {
        padding-top: 0px;
        font-family: 'Roboto Slab', serif;
        color: #5D2701;
        font-weight: 300;
    }
    .page-footer {
        margin-top: 0px;
    }
}

.studio-foto-menu {
    .navigation {
        padding: 30px 0;
        margin: 0 auto;
        max-width: 1000px;
        text-align: center;
        a {
            display: inline-block;
            border-left: 1px solid #5D2701;
            padding-left: 9px;
            margin-left: 7px;
            line-height: 15pt;
            color: #5D2701;
            font-size: 15pt;
            font-weight: 300;
            line-height: 15pt;
            text-transform: uppercase;
        }
        a:first-child {
            margin: 0;
            border: 0;
            padding: 0;
        }
        a.selected {
            color: #77207E;
            font-weight: 700;
        }
    }
}
.studio-foto-container {
    padding-top: 340px;
    height: 857px;
    box-sizing: border-box;
    background: #fff no-repeat center top;
    .content {
        position: relative;
        margin: 0 auto;
        max-width: 638px;
        height: 341px;
        text-align: center;
        font-size: 18pt;
        line-height: 30pt;
    }
    h1 {
        margin: 15px 0;
        text-transform: uppercase;
        color: #77207E;
        font-size: 46pt;
        font-weight: 100;
        font-family: 'Roboto Slab', serif;
        line-height: initial;
    }
    &.strefa-flash {
        background-image: url('i/sf/main_bg.jpg');
        h1 {
            margin: 0;
            position: relative;
            top: 44px;
            font-size: 72pt;
        }
        h2 {
            position: absolute;
            left: 0;
            top: 160px;
            width: 100%;
            text-transform: none;
            color: #000;
            font-size: 15.6pt;
            font-weight: 700;
            line-height: 13pt;
        }
    }
    &.strefa-flash-design {
        background-image: url('i/sf/designe_bg.jpg');
    }
    &.strefa-flash-product-story {
        background-image: url('i/sf/product_bg.jpg');
    }
    &.strefa-flash-corpo-story {
        background-image: url('i/sf/corpo_bg.jpg');
    }
    &.strefa-flash-home-made-story {
        background-image: url('i/sf/home_made_bg.jpg');
    }
    &.strefa-flash-kontakt {
        background-image: url('i/sf/contact_bg.jpg');
    }
}

/*
    Author     : rkiedrowski
*/


.video-list {
    text-align: center;
    padding-bottom: 10px;
    .header {
        &:extend(.about .header all);
        margin-top: 0;
    }
    .list {
        .item {
            margin: 0 auto 1em auto;
            text-align: left;
            &:hover {
                h2 {
                    color: #fff;
                    background: #000;
                    &:before {
                        position: absolute;
                        left: 0;
                        top: -4px;
                        content: "";
                        display: inline-block;
                        width: 100%;
                        height: 4px;
                        background: @r_color;
                    }
                }
            }
        }
        .thumb {
            position: relative;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -38px 0 0 -38px;
                width: 76px;
                height: 76px;
                background: url(i/video_ico.png) no-repeat;
            }
        }
        h2 {
            position: relative;
            margin: 0;
            padding: 5px;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2em;
            transition: background-color 0.5s;
        }
    }
}

.video-single {
    padding-bottom: 25px;
    .header {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        &:after {
            .mx-header-underscore;
            width: 25%;
        }
    }
    .thumb {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;
        iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .content {
        line-height: 1.5em;
        p {
            padding: 5px 0;
        }
    }
}